import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IProductSearchFilters } from '@models';
import { ModalService } from '@services/modal.service';
import { PowerSearchParamsModel } from '@shared/models/power-search-params.model';
import { RangeDualKnobsModel } from '@shared/models/range-dual-knobs.model';
import _findIndex from 'lodash-es/findIndex';

export interface IAdvancedSearchModalData {
  searchParams: PowerSearchParamsModel;
  currentValues: IProductSearchFilters | null;
}

@Component({
  selector: 'advanced-search.modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRange,
    IonToggle,
  ],
  styleUrls: ['advanced-search.modal.scss'],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" color="dark" (click)="clear()">
            Clear
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button
            fill="outline"
            shape="round"
            color="primary"
            (click)="search()"
            [strong]="true"
          >
            Submit
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="formGroup">
        <ion-list lines="full">
          <ion-item>
            <ion-select
              label="Manufacturer"
              label-placement="stacked"
              formControlName="Manufacturers"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Manufacturers"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-select
              label="Shape"
              label-placement="stacked"
              formControlName="Shapes"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Shapes"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-select
              label="Section"
              label-placement="stacked"
              formControlName="Sections"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Sections"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-select
              label="Cigar Origin"
              label-placement="stacked"
              formControlName="Origins"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Origins"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-range
              [snaps]="true"
              [min]="searchParams.Lengths[0]"
              [max]="searchParams.Lengths[searchParams.Lengths.length - 1]"
              labelPlacement="stacked"
              label="Length"
              class="dual-knobs"
              dualKnobs
              snaps="true"
              pin="true"
              ticks="false"
              formControlName="lengthRange"
            >
              <ion-label slot="start">{{ searchParams.Lengths[0] }}</ion-label>
              <ion-label slot="end">{{
                searchParams.Lengths[searchParams.Lengths.length - 1]
              }}</ion-label>
            </ion-range>
          </ion-item>
          <ion-item>
            <ion-range
              labelPlacement="stacked"
              label="Ring Gauge"
              [snaps]="true"
              [min]="searchParams.RingGauges[0]"
              [max]="
                searchParams.RingGauges[searchParams.RingGauges.length - 1]
              "
              class="dual-knobs"
              dualKnobs
              snaps="true"
              pin="true"
              ticks="false"
              formControlName="ringGaugeRange"
            >
              <ion-label slot="start">{{
                searchParams.RingGauges[0]
              }}</ion-label>
              <ion-label slot="end">{{
                searchParams.RingGauges[searchParams.RingGauges.length - 1]
              }}</ion-label>
            </ion-range>
          </ion-item>
          <ion-item>
            <ion-toggle
              mode="ios"
              labelPlacement="start"
              formControlName="strengthEnabled"
              (ionChange)="toggleStrenghtColor($event)"
              >Strength</ion-toggle
            >
          </ion-item>
          <ion-item lines="none" class="overflow-visible">
            <ion-range
              class="strength ml-2"
              step="1"
              [ticks]="false"
              [max]="searchParams.Strengths.length - 1"
              snaps="true"
              formControlName="strength"
              [ngClass]="{ opacity: !formGroup.value.strengthEnabled }"
            >
            </ion-range>
          </ion-item>
          <ion-item>
            <div class="slider-values strength">
              <span *ngFor="let item of searchParams.Strengths">{{
                item.Name
              }}</span>
            </div>
          </ion-item>

          <ion-item>
            <ion-select
              label="Wrapper"
              label-placement="stacked"
              [interfaceOptions]="customAlertOptions"
              interface="alert"
              formControlName="Wrappers"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Wrappers"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-toggle
              mode="ios"
              labelPlacement="start"
              formControlName="wrapperColorEnabled"
              (ionChange)="toggleWrapperColor($event)"
              >Wrapper Color</ion-toggle
            >
          </ion-item>
          <ion-item lines="none" class="overflow-visible">
            <ion-range
              class="color-wrapper ml-2"
              step="1"
              [ticks]="false"
              [max]="searchParams.WrapperColors.length - 1"
              snaps="true"
              formControlName="wrapperColor"
              [ngClass]="{ opacity: !formGroup.value.wrapperColorEnabled }"
            >
            </ion-range>
          </ion-item>
          <ion-item>
            <div class="slider-values vertical">
              <span
                *ngFor="
                  let item of searchParams.WrapperColors.slice().reverse()
                "
                >{{ item.Name }}</span
              >
            </div>
          </ion-item>
        </ion-list>
      </form>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchModal implements OnInit {
  customAlertOptions = {
    cssClass: 'ion-text-wrap',
  };

  formGroup = this.fb.group({
    Shapes: this.fb.control<number[]>(null),
    Sections: this.fb.control<number[]>(null),
    Origins: this.fb.control<number[]>(null),
    Wrappers: this.fb.control<number[]>(null),
    Manufacturers: this.fb.control<number[]>(null),
    lengthRange: this.fb.control<RangeDualKnobsModel>({
      lower: 0,
      upper: 11,
    }),
    ringGaugeRange: this.fb.control<RangeDualKnobsModel>({
      lower: 0,
      upper: 13,
    }),
    strengthEnabled: false,
    strength: 0,
    wrapperColorEnabled: false,
    wrapperColor: 0,
  });
  @Input() searchParams: PowerSearchParamsModel;
  @Input() currentValues: IProductSearchFilters | null;
  constructor(private modalService: ModalService, private fb: FormBuilder) {}

  ngOnInit() {
    const {
      LengthFrom = null,
      LengthTo = null,
      RingGaugeFrom = null,
      RingGaugeTo = null,
      Shapes = [],
      Origins = [],
      Sections = [],
      Wrappers = [],
      WrapperColors = [],
      Strengths = [],
      Manufacturers = [],
    } = this.currentValues || {};

    console.log({
      currentValues: this.currentValues,
      ww: this.searchParams.Lengths,
      upper: this.searchParams.Lengths[this.searchParams.Lengths.length - 1],
    });

    this.formGroup.setValue({
      lengthRange: {
        lower: LengthFrom || this.searchParams.Lengths[0],
        upper:
          LengthTo ||
          this.searchParams.Lengths[this.searchParams.Lengths.length - 1],
      },
      ringGaugeRange: {
        lower: RingGaugeFrom || this.searchParams.RingGauges[0],
        upper:
          RingGaugeTo ||
          this.searchParams.RingGauges[this.searchParams.RingGauges.length - 1],
      },
      wrapperColor: WrapperColors.length
        ? _findIndex(this.searchParams.WrapperColors.slice().reverse(), {
            Id: WrapperColors[0],
          })
        : 0,
      strength: Strengths.length
        ? _findIndex(this.searchParams.Strengths, {
            Id: Strengths[0],
          })
        : 0,
      Shapes,
      Origins,
      Sections,
      Wrappers,
      Manufacturers,
      strengthEnabled: Strengths.length > 0,
      wrapperColorEnabled: WrapperColors.length > 0,
    });
  }

  toggleWrapperColor(e: Event) {
    const checked = (<CustomEvent<{ checked: boolean }>>e).detail.checked;
    if (!checked) {
      this.formGroup.patchValue({
        wrapperColor: 0,
      });
    }
  }

  toggleStrenghtColor(e: Event) {
    const checked = (<CustomEvent<{ checked: boolean }>>e).detail.checked;
    if (!checked) {
      this.formGroup.patchValue({
        strength: 0,
      });
    }
  }

  search() {
    const {
      wrapperColorEnabled,
      wrapperColor,
      strengthEnabled,
      strength,
      lengthRange,
      ringGaugeRange,
      ...rest
    } = this.formGroup.value;
    console.log(this.formGroup.value);

    this.modalService.dismiss(
      {
        ...rest,
        WrapperColors: wrapperColorEnabled
          ? [this.searchParams.WrapperColors.slice().reverse()[wrapperColor].Id]
          : [],
        Strengths: strengthEnabled
          ? [this.searchParams.Strengths[strength].Id]
          : [],
        LengthFrom: lengthRange.lower,
        LengthTo: lengthRange.upper,
        RingGaugeFrom: ringGaugeRange.lower,
        RingGaugeTo: ringGaugeRange.upper,
      } as Partial<IProductSearchFilters>,
      'submit'
    );
  }

  clear() {
    this.modalService.dismiss(null, 'clear');
  }

  dismiss() {
    this.modalService.dismiss(null, 'cancel');
  }
}
