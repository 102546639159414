import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular/standalone';
import { IUserShort } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SocialService } from '@services/social.service';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { commentAtRegex, commentSpanRegex } from '@utils/regex';
import debug from 'debug';
import DOMPurify from 'dompurify';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Tribute from './tribute/Tribute';

const log = debug('cs:CommentFormComponent');

@UntilDestroy()
@Component({
  selector: 'comment-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CreateSourceStandalonePipe],
  providers: [CreateSourceStandalonePipe],
  template: `
    <form [formGroup]="formGroup" (submit)="comment()">
      <div
        #customInput
        [ngClass]="inputClass"
        class="custom-input prose max-w-none dark:text-white"
        contenteditable="true"
        (input)="input($event)"
        (keydown)="keydown($event)"
        [attr.data-placeholder]="placeholder"
      ></div>
    </form>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: block;
        background-color: var(--ion-background-color);
      }
      .custom-input {
        font-size: inherit;
        position: relative;
        display: inline-block;
        min-height: 200px;
        text-align: left;
        white-space: pre-wrap;
        overflow: auto;
        -webkit-user-select: text;
        user-select: text;
        outline: none;
        width: 100%;
        &.empty:before {
          content: attr(data-placeholder);
          color: var(--ion-color-step-500);
          pointer-events: none;
          position: absolute;
          left: 0;
          top: 0;
        }
        [contenteditable='false'] {
          user-select: none;
          pointer-events: none;
        }
      }
    `,
  ],
})
export class CommentFormComponent implements AfterContentInit {
  @Input() placeholder = 'Enter your comment here...';
  @Input() text = '';
  @Input() isLoading = false;
  @Input() inputClass = '';
  @Output() edit = new EventEmitter<string>();
  public formGroup = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
  });
  @ViewChild('customInput', { static: true }) customInput: ElementRef;

  search$ = new BehaviorSubject<{
    text: string;
    callback: (users: IUserShort[]) => void;
  }>({
    text: '',
    callback: () => {},
  });

  constructor(
    private platform: Platform,
    private socialService: SocialService,
    private createSourcePipe: CreateSourceStandalonePipe
  ) {}

  public initText(text = '') {
    this.customInput.nativeElement.innerHTML = text
      .replace(commentAtRegex, (match, id, label) => {
        return `<span userid="${id}">@${label}</span>&nbsp;`;
      })
      .replace(/\n/g, '<br/>');
    this.updateText(text);
  }

  ngAfterContentInit(): void {
    this.initText(this.text);

    const tribute = new Tribute<IUserShort>({
      trigger: '@',
      // column to search against in the object (accepts function or string)
      lookup: (item, text) => {
        return item.Nickname + ' ' + item.FirstName + ' ' + item.LastName;
      },
      // column that contains the content to insert by default
      fillAttr: 'value',
      selectClass: 'highlight',
      itemClass: '',
      allowSpaces: true,
      requireLeadingSpace: false,
      spaceSelectsMatch: false,
      menuShowMinLength: 1,
      values: async (text, callback) => {
        this.search$.next({
          text,
          callback,
        });
      },
      selectTemplate: function (item) {
        if (this.range.isContentEditable(this.current.element)) {
          const name = item.original.Nickname
            ? `@${item.original.Nickname}`
            : `@${item.original.FirstName} ${item.original.LastName}`;
          return `<span userid="${item.original.UserId}">${name}</span>`;
        }
        return '';
      },
      menuItemTemplate: (item) => {
        const name = item.original.Nickname
          ? item.original.Nickname
          : `${item.original.FirstName} ${item.original.LastName}`;
        return `
        <img class="w-10 h-10" src="${this.createSourcePipe.transform(
          item.original.AvatarUrl,
          'assets/images/user-image.png'
        )}">
        <span class="truncate">${name}</span>
        `;
      },
      loadingItemTemplate: '<div style="padding: 16px">Loading</div>',
    });

    // const contentEditableDiv = document.getElementById('test');
    tribute.attach(this.customInput.nativeElement);
    this.search$
      .pipe(
        untilDestroyed(this),
        filter((val) => val.text.trim().length > 0),
        debounce(() => interval(500)),
        distinctUntilChanged(),
        switchMap(({ text, callback }) => {
          return this.socialService.searchUsers(text).pipe(
            tap({
              next: (response) => {
                callback(response.body);
                // tribute.appendCurrent(response.body, true);
              },
              error: (error) => {
                callback([]);
              },
            })
          );
        })
      )
      .subscribe();

    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        untilDestroyed(this),
        tap((value) => {
          const hasText = value.text.trim().length > 0;
          if (hasText) {
            this.customInput.nativeElement.classList.remove('empty');
          } else {
            this.customInput.nativeElement.classList.add('empty');
          }
        })
      )
      .subscribe();
  }

  updateText(text = this.getSanitizedComment()) {
    log('updateText', text);
    this.formGroup.setValue({
      text,
    });
  }

  input(event) {
    log('input', event);
    this.updateText();
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const containerNode = range.startContainer;

    // Check if the input is happening inside a mention <span>
    // if (
    //   // @ts-ignore
    //   containerNode.nodeType === Node.TEXT_NODE &&
    //   containerNode.parentNode['tagName'] === 'SPAN'
    // ) {
    //   const spanNode = containerNode.parentNode;
    //   console.log({
    //     spanNode,
    //   });

    //   // Move the text outside the span element
    //   const newText = containerNode.nodeValue;
    //   const textNode = document.createTextNode(newText);

    //   // @ts-ignore
    //   spanNode.replaceWith(textNode); // Replace the mention span with plain text

    //   // Move the cursor to the end of the newly inserted text
    //   const newRange = document.createRange();
    //   newRange.setStart(textNode, textNode.length);
    //   newRange.collapse(true);
    //   selection.removeAllRanges();
    //   selection.addRange(newRange);
    // }

    const isInsertingSpace = event.data === ' ';

    console.log({
      containerNode,
      parentNode: containerNode.parentNode,
    });
    if (
      containerNode.nodeType === Node.TEXT_NODE &&
      containerNode.parentNode['tagName'] === 'SPAN'
    ) {
      const spanNode = containerNode.parentNode;
      const newText = containerNode.nodeValue;
      // Remove the mention span and move its content outside
      const textNodeBefore = document.createTextNode(
        newText.substring(0, range.startOffset)
      );
      const textNodeAfter = document.createTextNode(
        newText.substring(range.startOffset)
      );
      const atIndex = newText.indexOf('@');

      // console.log({
      //   isInsertingSpace,
      //   spanNode,
      //   newText,
      //   textNodeBefore,
      //   textNodeAfter,
      //   atIndex,
      // });

      if (atIndex > 0) {
        // There are characters before the @, move those outside of the span
        const textBeforeAt = newText.slice(0, atIndex);
        const textAfterAt = newText.slice(atIndex); // The mention starting with @

        // Insert the text before the @ outside of the span
        const textNodeBefore = document.createTextNode(textBeforeAt);
        spanNode.parentNode.insertBefore(textNodeBefore, spanNode);

        // Update the mention inside the span to start with @
        containerNode.nodeValue = textAfterAt; // Keep only the mention part inside

        // Move the cursor to after the text before the mention
        const newRange = document.createRange();
        newRange.setStartAfter(textNodeBefore);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      } else {
        // Insert the text outside the mention span
        spanNode.parentNode.insertBefore(textNodeBefore, spanNode);
        spanNode.parentNode.insertBefore(textNodeAfter, spanNode.nextSibling);
        // @ts-ignore
        spanNode.remove();

        // Move the cursor to the correct position after the typed text
        const newRange = document.createRange();
        newRange.setStart(textNodeAfter, 0); // Place cursor after the inserted text
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    }

    // if (
    //   containerNode.nodeType === Node.TEXT_NODE &&
    //   containerNode.parentNode['tagName'] === 'SPAN'
    // ) {
    //   const spanNode = containerNode.parentNode;
    //   const newText = containerNode.nodeValue;

    //   // If the cursor is inside the mention, allow modifications inside it
    //   if (range.startOffset > 0 && range.startOffset < newText.length) {
    //     // Let users modify inside the mention without removing the span
    //     return;
    //   }

    //   // Remove the mention span and move its content outside
    //   const textNodeBefore = document.createTextNode(
    //     newText.substring(0, range.startOffset)
    //   );
    //   const textNodeAfter = document.createTextNode(
    //     newText.substring(range.startOffset)
    //   );

    //   // Insert the text outside the mention span
    //   spanNode.parentNode.insertBefore(textNodeBefore, spanNode);
    //   spanNode.parentNode.insertBefore(textNodeAfter, spanNode.nextSibling);
    //   // @ts-ignore
    //   spanNode.remove();

    //   // Move the cursor to the correct position after the typed text
    //   const newRange = document.createRange();
    //   newRange.setStart(textNodeAfter, 0); // Place cursor after the inserted text
    //   newRange.collapse(true);
    //   selection.removeAllRanges();
    //   selection.addRange(newRange);
    // }
  }

  keydown(e: KeyboardEvent) {
    // const selection = window.getSelection();
    // const range = selection.getRangeAt(0);
    // const containerNode = range.startContainer;
    // if ((e.key === 'Backspace' || e.key === 'Delete') && range.collapsed) {
    //   const nodeBefore = range.startContainer.previousSibling;
    //   console.log({ containerNode });
    //   if (
    //     containerNode.nodeType === Node.TEXT_NODE &&
    //     containerNode.parentNode['tagName'] === 'SPAN'
    //   ) {
    //     const spanNode = containerNode.parentNode;
    //     e.preventDefault(); // Prevent the default behavior of backspace/delete
    //     // @ts-ignore
    //     spanNode.remove();
    //     // Move the cursor to the correct position after the typed text
    //     // const newRange = document.createRange();
    //     // newRange.setStart(textNodeAfter, 0); // Place cursor after the inserted text
    //     // newRange.collapse(true);
    //     // selection.removeAllRanges();
    //     // selection.addRange(newRange);
    //   }
    // }
    // if (event.key === 'Backspace') {
    //   const selection = window.getSelection();
    //   const range = selection.getRangeAt(0);
    //   if (range.collapsed) {
    //     const containerNode = range.startContainer;
    //     // Check if the backspace is within a mention span
    //     if (
    //       containerNode.nodeType === Node.TEXT_NODE &&
    //       containerNode.parentNode['tagName'] === 'SPAN'
    //     ) {
    //       const spanNode = containerNode.parentNode;
    //       if (containerNode.nodeValue.length === 1) {
    //         // If this is the last character in the mention
    //         event.preventDefault(); // Prevent default backspace behavior
    //         // @ts-ignore
    //         spanNode.remove(); // Remove the entire span (mention)
    //         // Move the cursor outside the span
    //         const newRange = document.createRange();
    //         newRange.setStartAfter(spanNode); // Move cursor just after the span
    //         newRange.collapse(true); // Collapse the range to a single point
    //         selection.removeAllRanges();
    //         selection.addRange(newRange); // Set the new range
    //       }
    //     }
    //   }
    // }
  }

  focus() {
    setTimeout(() => this.customInput.nativeElement.focus(), 0);
  }

  focusAndCursorToEnd() {
    this.focus();
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(this.customInput.nativeElement);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  public comment() {
    this.edit.emit(this.getSanitizedComment());
    if (this.platform.is('capacitor')) {
      Keyboard.hide();
    }
  }

  getSanitizedComment(): string {
    return DOMPurify.sanitize(this.customInput.nativeElement.innerHTML, {
      ALLOWED_TAGS: ['span', 'br'],
      ALLOWED_ATTR: ['userid'],
    })
      .replace(commentSpanRegex, (match, id, label) => {
        return `##${id}##`;
      })
      .replace(/<br\s*\/?>/g, '\n');
  }
}
