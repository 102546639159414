import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  LoadedImage,
} from 'ngx-image-cropper';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    ImageCropperComponent,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Move and scale</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="submit()" color="dark"> Use </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content [scrollY]="false" [scrollX]="false" class="ion-padding">
      <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
      <div class="h-full flex items-center justify-center">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageFile]="blob"
          [maintainAspectRatio]="maintainAspectRatio"
          [aspectRatio]="aspectRatio"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <!-- <img [src]="croppedImage" /> -->
    </ion-content>
  `,
})
export class ImageCropperModal implements OnInit {
  @Input() blob: Blob | null = null;
  @Input() aspectRatio = 1 / 1;
  @Input() maintainAspectRatio = true;
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';
  croppedBlob: Blob | null = null;
  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {}

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.croppedBlob = event.blob;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  submit() {
    this.modalService.dismiss(
      {
        blob: this.croppedBlob,
      },
      'success'
    );
  }

  dismiss() {
    this.modalService.dismiss({}, 'cancel');
  }
}
