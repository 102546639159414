import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ICigarDistinct, IState } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CigarsService } from '@services/cigars.service';
import { ModalService } from '@services/modal.service';
import { UserService } from '@services/user.service';
import { CigarInfoComponent } from '@shared/components/cigar-info/cigar-info.component';
import { ProductModel } from '@shared/models/product.model';
import * as socialActions from '@store/actions/social';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import {
  debounceTime,
  finalize,
  first,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

export enum SocialProductPostRoles {
  CANCELED = 'canceled',
  SUCCESS = 'success',
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CigarInfoComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonProgressBar,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  ],
  selector: 'product-post',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>My Cigars</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-searchbar
        mode="ios"
        placeholder="Search"
        [formControl]="searchTextControl"
      ></ion-searchbar>
      <ion-progress-bar
        type="indeterminate"
        [style.visibility]="isLoading ? 'visible' : 'hidden'"
      ></ion-progress-bar>
    </ion-header>

    <ion-content>
      <ion-list lines="none">
        <ion-item color="light" *ngIf="!isLoading && !list.length">
          <ion-label>Nothing here</ion-label>
        </ion-item>
        <cigar-info
          *ngFor="let cigar of list"
          [cigar]="cigar"
          [name]="cigar.Name"
          [rating]="cigar.Rating"
          [myRating]="cigar.UserRating"
          [comment]="cigar.UserRating?.Comment"
          (cigarClicked)="createPost(cigar)"
        >
        </cigar-info>
      </ion-list>
      <ion-infinite-scroll
        [disabled]="isLoading || reachedEnd"
        threshold="100px"
        (ionInfinite)="loadMore($event)"
      >
        <ion-infinite-scroll-content> </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  `,
  styles: [
    `
      cigar-info {
        border-color: var(
          --ion-item-border-color,
          var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
        );
        border-width: 0 0 1px 0;
        border-style: solid;
        padding: 5px 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPostModal implements OnInit {
  isLoading = false;
  reachedEnd = false;
  list: ICigarDistinct[] = [];
  total = 0;
  searchTextControl = this.fb.nonNullable.control('');
  @ViewChild(IonContent, { static: true }) content: IonContent;

  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private cigarsService: CigarsService,
    private cdr: ChangeDetectorRef,
    private store: Store<IState>,
    private readonly actions$: Actions,
    private userService: UserService
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.searchTextControl.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.searchTextControl.value),
        debounceTime(500),
        switchMap(() => {
          this.list = [];
          this.reachedEnd = false;
          return this.loadList().pipe(
            finalize(() => {
              this.content.scrollToTop();
            })
          );
        })
      )
      .subscribe();
  }

  loadList() {
    this.isLoading = true;
    this.cdr.markForCheck();
    return this.cigarsService
      .getMyDistinctList(this.list.length, this.searchTextControl.value)
      .pipe(
        tap((response) => {
          this.total = Number(response.headers.get('X-Total-Count') ?? '0');
          const list = response.body ?? [];
          this.list = [...this.list, ...list];
          if (this.list.length >= this.total) {
            this.reachedEnd = true;
          }
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      );
  }

  loadMore(event?: InfiniteScrollCustomEvent) {
    this.loadList()
      .pipe(
        finalize(() => {
          event.target.complete();
        })
      )
      .subscribe();
  }

  async createPost(product: ICigarDistinct) {
    // this.loader.show();
    // this.socialPostData
    //   .createPost(new ProductModel(product))
    //   .pipe(
    //     tap((post) => {
    //       this.modalService.dismiss({ post }, SocialProductPostRoles.SUCCESS);
    //     }),
    //     finalize(() => this.loader.hide())
    //   )
    //   .subscribe();
    this.isLoading = true;
    this.cdr.markForCheck();

    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostCreateSuccess,
          socialActions.SocialPostCreateError
        ),
        first(),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((action) => {
        if (action.type === socialActions.SocialPostCreateSuccess.type) {
          this.modalService.dismiss(
            { post: action.post },
            SocialProductPostRoles.SUCCESS
          );
        }
      });

    const post = new ProductModel(product);
    post.Location = await this.userService.getLocationIfAllowed();
    this.store.dispatch(
      socialActions.SocialPostCreateRequest({
        post,
      })
    );
  }

  dismiss() {
    this.modalService.dismiss({}, SocialProductPostRoles.CANCELED);
  }
}
