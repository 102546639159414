import { ISocialState, IState } from '@models';
import { IUserShort } from '@models/user';
import {
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';
import { getUserTypeAndId } from '@utils/users';

import {
  adapter,
  getSocialCollectionInitialState,
  NAMESPACE,
} from '../reducers/social';
import { selectCommentsState, selectPostComments } from './comments';

export const selectSocialState = createFeatureSelector<ISocialState>(NAMESPACE);

export const selectWithArguments = (projector: Function) => {
  return createSelectorFactory((projectorFn) =>
    defaultMemoize(projectorFn, (a, b) => a === b)
  )(projector);
};

export const selectEntities = createSelector(
  selectSocialState,
  (state) => state.entities
);
export const selectCollections = createSelector(
  selectSocialState,
  (state) => state.collections
);
const selectIsLikeLoading = createSelector(
  selectSocialState,
  (state) => state.isLikeLoading
);
const selectIsCommentLoading = createSelector(
  selectSocialState,
  (state) => state.isCommentLoading
);
const selectIsFollowLoading = createSelector(
  selectSocialState,
  (state) => state.isFollowLoading
);

export const getPost = (postId: number) =>
  createSelector(selectEntities, (entities) => entities[postId]);

export const isLikeLoading = (postId: number) =>
  createSelector(selectIsLikeLoading, (state) => state[postId] === true);

export const isCommentLoading = (postId: number) =>
  createSelector(selectIsCommentLoading, (state) => state[postId] === true);

export const isFollowLoading = (user: IUserShort) => {
  const [_userType, userId] = getUserTypeAndId(user);
  return createSelector(
    selectIsFollowLoading,
    (state) => state[userId] === true
  );
};

export function getSocialSelectors(collectionId: string) {
  const getCollectionStore = (state: IState) => {
    const socialStore = selectSocialState(state);
    return (
      socialStore.collections[collectionId] || getSocialCollectionInitialState()
    );
  };
  const { selectIds } = adapter.getSelectors(getCollectionStore);

  return {
    selectAll: createSelector(selectIds, selectEntities, (ids, entities) =>
      ids?.map((id) => entities[id])
    ),
    isLoading: createSelector(getCollectionStore, (state) => state.isLoading),
    showMore: createSelector(getCollectionStore, (state) => state.showMore),
    length: createSelector(
      getCollectionStore,
      (state) => state.ids?.length || 0
    ),
  };
}
