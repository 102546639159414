import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appDynamicRouter]',
  standalone: true,
})
export class DynamicRouterDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const anchors = this.el.nativeElement.querySelectorAll('a[routerlink]');
    // console.log({ anchors });
    anchors.forEach((anchor: HTMLAnchorElement) => {
      const route = anchor.getAttribute('routerlink');
      // console.log({ route });
      if (route) {
        this.renderer.listen(anchor, 'click', (event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          this.router.navigate([route]);
        });
      }
    });
  }
}
