import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DocumentPresenterModal } from '@components/document-presenter/document-presenter.modal';
import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IState } from '@models';
import { ArticlesEnum } from '@models/articles';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { ModalsService } from '@services/modals.service';
import { UserService } from '@services/user.service';
import { SocialPostModel } from '@shared/models/social-post.model';
import { CameraService } from '@shared/services/camera.service';
import { CigarLogInfoService } from '@shared/services/cigar-log-info.service';
import { LoaderService } from '@shared/services/loader.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import * as socialActions from '@store/actions/social';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { getErrorMessage } from '@utils/errors';
import debug from 'debug';
import { addIcons } from 'ionicons';
import {
  closeOutline,
  imageOutline,
  locationSharp,
  send,
} from 'ionicons/icons';
import { firstValueFrom } from 'rxjs';
import { finalize, first, take, tap } from 'rxjs/operators';

import { LocationModal } from './location-modal.component';

export enum CustomPost2Roles {
  CANCELED = 'canceled',
  SUCCESS = 'success',
}

const log = debug('cs:CustomPost');

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonProgressBar,
    IonList,
    IonItem,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonFooter,
    IonInput,
    IonTextarea,
    IonChip,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ post ? 'Edit post' : 'Create post' }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content (touchmove)="stopTouchPropagation($event)">
      <ion-item color="danger" *ngIf="error">
        <ion-label class="ion-text-wrap"> {{ error }} </ion-label>
      </ion-item>
      <form [formGroup]="formGroup">
        <ion-input hidden type="hidden" formControlName="ImageUrl" required>
        </ion-input>
        <ion-progress-bar
          type="indeterminate"
          [style.visibility]="isLoading ? 'visible' : 'hidden'"
        ></ion-progress-bar>
        <!-- <img [src]="imgSrc" alt="add to homescreen tutorial" /> -->

        <ion-list lines="full">
          <ion-item>
            <ion-label position="floating">Text</ion-label>
            <ion-textarea
              rows="8"
              formControlName="Text"
              [autoGrow]="true"
            ></ion-textarea>
            <ion-note
              color="danger"
              class="form-errors"
              *ngIf="
                formGroup.touched && formGroup.get('Text').hasError('required')
              "
            >
              <p>required</p>
            </ion-note>
          </ion-item>
          <ion-item *ngIf="isGuest$ | async">
            <ion-label
              >Agree to
              <a
                slot="end"
                href="javascript:void(0);"
                (click)="openTermsModal($event)"
                >terms and conditions</a
              ></ion-label
            >
            <ion-checkbox name="terms" formControlName="Terms"></ion-checkbox>
            <ion-note
              slot="end"
              class="ion-no-margin"
              *ngIf="
                formGroup.touched && formGroup.get('Terms').hasError('required')
              "
              color="danger"
            >
              <p>required</p>
            </ion-note>
          </ion-item>
        </ion-list>
        <ion-chip
          [outline]="true"
          color="primary"
          (click)="openLocationModal()"
          [disabled]="formGroup.get('Location').disabled"
          *ngIf="formGroup.get('Location').value"
        >
          <ion-icon name="location-sharp" class="mr-1 ml-0"></ion-icon>
          {{ formGroup.get('Location').value }}
        </ion-chip>

        <ion-grid>
          <ion-row class="ion-justify-content-center ion-align-items-center">
            <ion-col>
              <ion-card
                class="media-photo"
                *ngIf="
                  !isPhotoLoading && this.formGroup.get('ImageUrl').value as src
                "
                (click)="takePicture()"
              >
                <img [src]="src" />
              </ion-card>
              <ion-card class="media-spinner" *ngIf="isPhotoLoading">
                <ion-spinner></ion-spinner>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <!-- <ion-list-header>
          <ion-label>Add a media</ion-label>
        </ion-list-header> -->
        <div
          class="ion-text-center ion-margin-vertical"
          *ngIf="!this.formGroup.get('ImageUrl').value && !isPhotoLoading"
        >
          <ion-button
            fill="outline"
            color="primary"
            (click)="takePicture()"
            [disabled]="isPhotoLoading"
          >
            <ion-icon slot="start" name="image-outline"></ion-icon>Photo
          </ion-button>
          <!-- <ion-button fill="outline" color="primary" (click)="takePicture()">
            <ion-icon name="list-outline"></ion-icon>Cigar</ion-button
          >
          <ion-button fill="outline" color="primary" (click)="takePicture()"
            ><ion-icon name="star-half-outline"></ion-icon> Review</ion-button
          > -->
          <ion-note
            color="danger"
            class="form-errors"
            *ngIf="
              formGroup.touched &&
              formGroup.get('ImageUrl').hasError('required')
            "
          >
            <p>An image is required</p>
          </ion-note>
        </div>
      </form>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-button
          slot="start"
          (click)="openLocationModal()"
          size="large"
          [disabled]="formGroup.get('Location').disabled"
          [color]="formGroup.get('Location').value ? 'primary' : 'medium'"
          type="button"
          fill="clear"
        >
          <ion-icon
            class="text-3xl"
            slot="icon-only"
            name="location-sharp"
          ></ion-icon>
        </ion-button>
        <ion-button
          slot="end"
          (click)="submit()"
          size="large"
          color="primary"
          type="button"
          [disabled]="isLoading || isPhotoLoading"
        >
          <ion-icon slot="icon-only" name="send"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  `,
  styles: [
    `
      ion-card[class^='media'] {
        display: flex;
        width: fit-content;
        margin: auto;
      }
      .media-photo {
      }
      .media-spinner {
        padding: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPostModal implements OnInit {
  error = '';
  isLoading = false;
  isPhotoLoading = false;
  formGroup = this.fb.group({
    Location: ['', []],
    Text: ['', [Validators.required]],
    ImageUrl: ['', [Validators.required]],
  });
  @Input() post: SocialPostModel;
  isGuest$ = this.store.select(userSelectors.selectIsGuest);

  constructor(
    private modalService: ModalService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private cameraService: CameraService,
    private cigarInfoService: CigarLogInfoService,
    private store: Store<IState>,
    private readonly actions$: Actions,
    private loader: LoaderService,
    private storage: LocalStorageService,
    private userService: UserService,
    private modalsService: ModalsService
  ) {
    this.isGuest$
      .pipe(
        take(1),
        tap((isGuest) => {
          if (isGuest) {
            this.formGroup.addControl(
              'Terms',
              this.fb.control(false, [Validators.requiredTrue])
            );
          }
        })
      )
      .subscribe();
    addIcons({ closeOutline, imageOutline, send, locationSharp });
  }

  ngOnInit() {
    if (this.post) {
      this.formGroup.patchValue(this.post);
      this.formGroup.get('Location').disable();
    } else {
      this.setLocation();
    }
  }

  /**
   * This is to avoid closing the modal when the user is interacting with the form
   * @param e TouchEvent
   */
  stopTouchPropagation(e: TouchEvent) {
    e.stopPropagation();
  }

  async setLocation() {
    const location = await this.userService.getLocationIfAllowed();
    this.formGroup.get('Location').setValue(location);
    this.cdr.markForCheck();
  }

  async openLocationModal() {
    const modal = await this.modalService.createCSModal(LocationModal, {
      location: this.formGroup.get('Location').value,
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'success' && data) {
      this.storage.set(LocalStorageService.locationKey, data);
      this.formGroup.get('Location').setValue(data);
      this.store.dispatch(
        userActions.SettingsUpdateRequest({
          settings: { ShareLocation: true },
        })
      );
    } else if (role === 'clear') {
      this.storage.remove(LocalStorageService.locationKey);
      this.formGroup.get('Location').setValue('');
      this.store.dispatch(
        userActions.SettingsUpdateRequest({
          settings: { ShareLocation: false },
        })
      );
    }
    this.cdr.markForCheck();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.post && changes.post.currentValue) {
  //   }
  // }

  async openTermsModal(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const modal = await this.modalService.createCSModal(
      DocumentPresenterModal,
      {
        articleId: ArticlesEnum.EULA,
      }
    );
    await modal.present();
  }

  // async determineLocation() {
  //   const cigarLocationControl = this.formGroup.get('Location');
  //   log('determineLocation');
  //   try {
  //     this.error = '';
  //     this.isLoading = true;
  //     this.cdr.markForCheck();
  //     cigarLocationControl.disable({ emitEvent: false });
  //     const address = await this.locationService.getLocationAddress2();
  //     cigarLocationControl.setValue(address, { emitEvent: false });
  //   } catch (error) {
  //     this.toastService.show('Could not determine your location');
  //     console.error('could not determine location');
  //   }
  //   this.isLoading = false;
  //   cigarLocationControl.enable({ emitEvent: false });
  //   this.cdr.markForCheck();
  // }

  dismiss() {
    this.modalService.dismiss({}, CustomPost2Roles.CANCELED);
  }

  async uploadImage() {}

  async takePicture() {
    this.error = '';
    try {
      const { image } = await this.cameraService.showCameraOptions();
      if (!image) throw new Error('no image selected');
      this.isPhotoLoading = true;
      this.cdr.markForCheck();
      const modal = await this.modalsService.openImageCropperModal({
        blob: image.file,
        maintainAspectRatio: false,
      });
      await modal.present();
      const { role, data } = await modal.onDidDismiss();
      if (role === 'success') {
        const imageUrl = await firstValueFrom(
          this.cigarInfoService.getImageUrl(data.blob)
        );
        this.formGroup.get('ImageUrl').setValue(imageUrl);
      }
    } catch (error) {
      // error === false when picture went wrong but we do not want to display the error
      // for instance when the user cancel or no image selected
      if (error === false) return;
      this.error = getErrorMessage(error);
    }
    this.isPhotoLoading = false;
    this.cdr.markForCheck();
  }

  successDismiss(post: SocialPostModel) {
    this.modalService.dismiss(
      {
        post,
      },
      CustomPost2Roles.SUCCESS
    );
  }

  submit() {
    const value = this.formGroup.value as SocialPostModel;
    log('submit', { value });
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.cdr.markForCheck();
      return;
    }

    this.loader.show();
    this.isLoading = true;
    this.cdr.markForCheck();

    if (this.post) {
      this.actions$
        .pipe(
          ofType(
            socialActions.SocialPostEditSuccess,
            socialActions.SocialPostEditError
          ),
          first(),
          finalize(() => {
            this.isLoading = false;
            this.loader.hide();
            this.cdr.markForCheck();
          })
        )
        .subscribe((action) => {
          if (action.type === socialActions.SocialPostEditSuccess.type) {
            this.successDismiss(action.post);
          }
        });
      this.store.dispatch(
        socialActions.SocialPostEditRequest({
          postId: this.post.Id,
          post: value,
        })
      );
    } else {
      this.actions$
        .pipe(
          ofType(
            socialActions.SocialPostCreateSuccess,
            socialActions.SocialPostCreateError
          ),
          first(),
          finalize(() => {
            this.isLoading = false;
            this.loader.hide();
            this.cdr.markForCheck();
          })
        )
        .subscribe((action) => {
          if (action.type === socialActions.SocialPostCreateSuccess.type) {
            this.successDismiss(action.post);
          }
        });
      this.store.dispatch(
        socialActions.SocialPostCreateRequest({
          post: value,
        })
      );
    }
  }
}
