import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { UserReview } from '@components/user-review';
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IState } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFor } from '@rx-angular/template/for';
import { ModalService } from '@services/modal.service';
import { SocialService } from '@services/social.service';
import { UserService } from '@services/user.service';
import { ShareReviewModel } from '@shared/models/share-review.model';
import { UserProfileModel } from '@shared/models/user-profile.model';
import { UserReviewModel } from '@shared/models/user-review.model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { ReviewListGroupByDatePipe } from '@shared/pipes/review-list-group-by-date.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { DeviceService } from '@shared/services/device.service';
import * as socialActions from '@store/actions/social';
import * as userSelectors from '@store/selectors/user';
import { getUserType } from '@utils/users';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import {
  debounceTime,
  finalize,
  first,
  mergeMap,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

export enum SocialProductReviewsRoles {
  CANCELED = 'canceled',
  SUCCESS = 'success',
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonButtons,
    IonList,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonSearchbar,
    IonItemDivider,
    IonLabel,
    IonItemGroup,
    CommonModule,
    UserReview,
    ReactiveFormsModule,
    CreateSourceStandalonePipe,
    RxFor,
    UserNamePipe,
    ReviewListGroupByDatePipe,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>My Reviews</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-searchbar
        mode="ios"
        placeholder="Search"
        [formControl]="searchTextControl"
      ></ion-searchbar>
      <ion-progress-bar
        type="indeterminate"
        *ngIf="isLoading"
      ></ion-progress-bar>
    </ion-header>

    <ion-content>
      <ion-list lines="none">
        <ion-item color="light" *ngIf="!isLoading && !list.length">
          <ion-label>no reviews</ion-label>
        </ion-item>
        <ion-item-group
          *rxFor="let group of list | reviewListGroupByDate; trackBy: 'date'"
        >
          <ion-item-divider sticky>
            <ion-label> {{ group.date }} </ion-label>
          </ion-item-divider>
          <user-review
            *rxFor="let review of group.list; trackBy: 'Id'"
            [review]="review"
            [showDate]="false"
            (click)="createPost(review.Id)"
          ></user-review>
        </ion-item-group>
      </ion-list>
      <ion-infinite-scroll
        [disabled]="reachedEnd"
        threshold="100px"
        (ionInfinite)="loadMore($event)"
      >
        <ion-infinite-scroll-content> </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  `,
  styles: [
    `
      :host {
        user-review {
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyReviewsPostModal implements OnInit, AfterViewInit {
  list: UserReviewModel[] = [];
  total = 0;
  user: UserProfileModel;
  isLoading = false;
  reachedEnd = false;
  searchTextControl = this.fb.nonNullable.control('');
  @ViewChild(IonContent, { static: true }) content: IonContent;
  accountId$ = this.store.select(userSelectors.selectAccountId);

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private socialService: SocialService,
    private deviceService: DeviceService,
    private cdr: ChangeDetectorRef,
    private store: Store<IState>,
    private readonly actions$: Actions,
    private userService: UserService
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.searchTextControl.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.searchTextControl.value),
        debounceTime(500),
        switchMap(() => {
          this.list = [];
          this.reachedEnd = false;
          return this.loadList().pipe(
            finalize(() => {
              this.content.scrollToTop();
            })
          );
        })
      )
      .subscribe();
  }

  loadList() {
    this.isLoading = true;
    this.cdr.detectChanges();

    return this.accountId$.pipe(
      mergeMap((accountId) =>
        this.socialService
          .searchReviews(
            accountId || this.deviceService.getDeviceID(),
            getUserType(accountId),
            { Search: this.searchTextControl.value },
            this.list.length
          )
          .pipe(
            tap((response) => {
              this.total = Number(response.headers.get('X-Total-Count') ?? '0');
              const list = response.body ?? [];
              this.list = [...this.list, ...list];
              if (this.list.length >= this.total) {
                this.reachedEnd = true;
              }
            }),
            finalize(() => {
              this.isLoading = false;
              this.cdr.detectChanges();
            })
          )
      )
    );
  }

  loadMore(event?: InfiniteScrollCustomEvent) {
    this.loadList()
      .pipe(
        finalize(() => {
          event.target.complete();
        })
      )
      .subscribe();
  }
  async createPost(reviewId: number) {
    const shareReviewData = {} as ShareReviewModel;

    shareReviewData.ReviewId = reviewId;
    shareReviewData.Location = await this.userService.getLocationIfAllowed();
    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostCreateSuccess,
          socialActions.SocialPostCreateError
        ),
        first(),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((action) => {
        if (action.type === socialActions.SocialPostCreateSuccess.type) {
          this.modalService.dismiss(
            { post: action.post },
            SocialProductReviewsRoles.SUCCESS
          );
        }
      });

    this.store.dispatch(
      socialActions.SocialPostCreateRequest({
        post: shareReviewData,
      })
    );
  }

  dismiss() {
    this.modalService.dismiss({}, SocialProductReviewsRoles.CANCELED);
  }
}
