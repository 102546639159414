import { RxLet } from '@rx-angular/template/let';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular/standalone';
import { IState } from '@models';
import { IUserShort } from '@models/user';
import { Store } from '@ngrx/store';
import { ModalsService } from '@services/modals.service';
import { IComment } from '@shared/models/comment-model';
import * as socialActions from '@store/actions/social';
// import * as commentsActions from '@store/actions/social';
import * as userSelectors from '@store/selectors/user';
import { getUserTypeAndId } from '@utils/users';
import * as commentsSelectors from '@store/selectors/comments';

import { CommentComponent } from './comment.component';
import { Observable } from 'rxjs';
import { CommentSkeletonComponent } from './comment.skeleton.component';

@Component({
  selector: 'social-post-comment-container',
  standalone: true,
  imports: [CommonModule, CommentComponent, CommentSkeletonComponent, RxLet],
  template: `
    <social-post-comment
      [comment]="comment"
      [isHighlighted]="highlightedComment === comment.Id"
      [isOwner]="isOwner$(comment.User) | async"
      (editComment)="openEditCommentModal($event)"
      (deleteComment)="deletePrompt($event)"
      (reportComment)="reportComment($event)"
      (toggleCommentLike)="toggleCommentLike($event)"
      (reply)="reply.emit($event)"
      (blockUser)="blockUser($event)"
      (commentClick)="commentClick.emit(comment)"
      [hasMore]="comment.Comments"
    >
      <!-- <ng-content></ng-content> -->

      <ng-container
        *rxLet="
          isThreadLoading$(comment.PostId, comment.Id);
          let isThreadLoading
        "
      >
        <ng-container *ngIf="showThreads">
          <social-post-comment
            *ngFor="
              let childComment of comment.ThreadComments
                | slice
                  : 0
                  : (!isThreadLoading && threadExpended
                      ? maxExpendedThreadLength
                      : 1);
              let i = index;
              let last = last;
              trackBy: trackByCommendId
            "
            [comment]="childComment"
            [isHighlighted]="highlightedComment === childComment.Id"
            [isOwner]="isOwner$(childComment.User) | async"
            (editComment)="openEditCommentModal($event)"
            (deleteComment)="deletePrompt($event)"
            (reportComment)="reportComment($event)"
            (toggleCommentLike)="toggleCommentLike($event)"
            (reply)="reply.emit($event)"
            (blockUser)="blockUser($event)"
            (commentClick)="commentClick.emit(childComment)"
            [hasMore]="last ? comment.ThreadComments.length > i + 1 : true"
          >
          </social-post-comment>
        </ng-container>

        <social-post-skeleton-comment
          *ngIf="isThreadLoading"
        ></social-post-skeleton-comment>
        <div
          class="relative pl-8 bg-[var(--ion-background-color)]"
          *ngIf="!threadExpended && comment.Comments > 1"
        >
          <div
            class="relative pl-5 flex items-start -ml-px space-x-3 border-0 border-l-2 border-dashed border-gray-200 dark:border-gray-600"
          >
            <ion-button
              [disabled]="isThreadLoading"
              (click)="showReplies()"
              fill="clear"
              size="small"
            >
              Show {{ comment.Comments - 1 }} replies
            </ion-button>
          </div>
        </div>
      </ng-container>
    </social-post-comment>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentContainer {
  threadExpended = false;
  maxExpendedThreadLength = Infinity;
  // isCommenting = false;
  // isOwner = false;
  @Input() comment: IComment;
  @Input() postAuthor?: IUserShort;
  @Input() highlightedComment: number;
  @Input() showMenu = true;
  @Input() hasMore = true;
  @Input() showThreads = true;
  @Output() commentClick = new EventEmitter<IComment>();
  @Output() edit = new EventEmitter<string>();
  @Output() reply = new EventEmitter<IComment>();

  isSameUser$ = this.store.select((state) =>
    userSelectors.isSameUser(this.comment?.User)(state)
  );

  isOwner$ = (User: IUserShort): Observable<boolean> => {
    return this.store.select((state) => userSelectors.isSameUser(User)(state));
  };

  isThreadLoading$ = (
    PostId: number,
    ParentId?: number
  ): Observable<boolean> => {
    return this.store.select((state) =>
      commentsSelectors.isThreadLoading(PostId, ParentId)(state)
    );
  };

  constructor(
    public popoverCtrl: PopoverController,
    private store: Store<IState>,
    private modalsService: ModalsService,
    private alertCtrl: AlertController,
    private cdr: ChangeDetectorRef
  ) {}

  trackByCommendId(index: number, comment: IComment) {
    return comment.Id;
  }

  // async openCommentReplyModal(comment: IComment) {
  //   const modal = await this.modalsService.getCommentModalV2({
  //     postId: comment.PostId,
  //     commentParentId: comment.ParentId || comment.Id, // avoid more than 2 levels
  //     postAuthor: this.postAuthor,
  //     replyTo: comment.User,
  //   });
  //   // this.isCommenting = true;
  //   // this.cdr.markForCheck();
  //   await modal.present();
  // }

  async openEditCommentModal(comment: IComment) {
    const modal = await this.modalsService.getCommentModalV2({
      text: comment.Text,
      postId: comment.PostId,
      commentId: comment.Id,
    });
    // this.isCommenting = true;
    // this.cdr.markForCheck();
    await modal.present();
    // const { role, data } = await modal.onWillDismiss();
    // if (role === 'update') {
    //   this.store.dispatch(
    //     socialActions.SocialPostEditCommentRequest({
    //       postId: comment.PostId,
    //       commentId: comment.Id,
    //       text: data.text,
    //     })
    //   );
    // }
  }

  toggleCommentLike(comment: IComment) {
    this.store.dispatch(
      socialActions.SocialPostToggleCommentLikeRequest({
        postId: comment.PostId,
        commentId: comment.Id,
        isLiked: !!comment.Liked,
      })
    );
  }

  showReplies() {
    this.threadExpended = true;
    this.store.dispatch(
      socialActions.SocialPostLoadCommentsRequest({
        postId: this.comment.PostId,
        parentId: this.comment.Id,
      })
    );
  }

  blockUser(e: { commentId: number; user: IUserShort }) {
    const [userType, userId] = getUserTypeAndId(e.user);
    this.store.dispatch(
      socialActions.SocialBlockUserRequest({
        userId,
        userType,
      })
    );
  }

  reportComment(e: { commentId: number; reasonId: number }) {
    this.store.dispatch(
      socialActions.SocialPostReportCommentRequest({
        commentId: e.commentId,
        reasonId: e.reasonId,
      })
    );
  }

  deletePrompt(comment) {
    this.alertCtrl
      .create({
        header: 'Delete comment',
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Delete',
            handler: () => {
              this.store.dispatch(
                socialActions.SocialPostRemoveCommentRequest({
                  postId: comment.PostId,
                  commentId: comment.Id,
                })
              );
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
}
