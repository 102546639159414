import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, Platform } from '@ionic/angular/standalone';
import HumidorHistoryModal from '@modals/humidor-history-modal.component';
import {
  ScannerModal,
  ScannerModalResult,
} from '@modals/scanner-modal/scanner-modal';
import {
  ScannerNoResultsModal,
  ScannerNoResultsModalResult,
} from '@modals/scanner-modal/scanner-no-results-modal';
import {
  ScannerAIResultsModal,
  ScannerAIResultsModalResult,
} from '@modals/scanner-modal/scanner-results-modal';
import { SearchModal } from '@modals/search-modal.component';
import { ICigar, ICigarLogListType, IScan, IState, IUserShort } from '@models';
import { select, Store } from '@ngrx/store';
import { ToastService } from '@services/toast.service';
import { JOURNAL_LIST } from '@shared/models/cigar-log.model';
import { CameraService } from '@shared/services/camera.service';
import * as myCigarsActions from '@store/actions/my-cigars';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { getCigarCreatedId } from '@utils/cigar';
import { isListHumidor } from '@utils/cigarlogs';
import { getErrorMessage } from '@utils/errors';
import { AppRoutes } from '@utils/routes';
import { first, firstValueFrom, take, tap } from 'rxjs';

import { ModalService } from './modal.service';
import { ImageCropperModal } from '@modals/image-cropper-modal.component';
import { LocationModal } from '@modals/location-modal.component';
import { SocialCommentV2Modal } from '@modals/social-commentv2-modal.component';
import { LoginModal } from '@modals/login.modal.component';
import { CustomPostModal } from '@modals/social-custom-post-modal.component';
import { MyReviewsPostModal } from '@modals/social-my-reviews-post-modal.component';
import { ProductPostModal } from '@modals/social-product-post-modal.component';
import { SocialCommentModal } from '@modals/social-comment-modal.component';

@Injectable({ providedIn: 'root' })
export class ModalsService {
  settings$ = this.store.pipe(select(userSelectors.selectSettings));
  isAuthenticated$ = this.store.select(userSelectors.selectIsAuthenticated);

  constructor(
    private modalService: ModalService,
    private store: Store<IState>,
    private cameraService: CameraService,
    private toastService: ToastService,
    private router: Router,
    public platform: Platform,
    private actionSheetCtrl: ActionSheetController
  ) {}

  async openSocialPostMenu() {
    this.isAuthenticated$
      .pipe(
        take(1),
        tap(async (isAuthenticated) => {
          if (!isAuthenticated) {
            this.openLoginModal();
            return;
          }

          const actionSheet = await this.actionSheetCtrl.create({
            header: `Post`,
            buttons: [
              {
                text: 'Photos',
                handler: () => {
                  this.openCustomPostModal();
                },
              },
              {
                text: 'My Cigars',
                handler: () => {
                  this.openMyCigarModal();
                },
              },
              {
                text: 'My Reviews',
                handler: () => {
                  this.openReviewPostModal();
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                data: {
                  action: 'cancel',
                },
              },
            ],
          });

          await actionSheet.present();
        })
      )
      .subscribe();
  }

  async openLoginModal() {
    const modal = await this.modalService.createCSModal(LoginModal, {});
    return modal.present();
  }

  async openCustomPostModal() {
    const modal = await this.modalService.createCSModal(CustomPostModal, {});
    await modal.present();
  }

  async openReviewPostModal() {
    const modal = await this.modalService.createCSModal(MyReviewsPostModal, {});
    await modal.present();
  }

  async openMyCigarModal() {
    const modal = await this.modalService.createCSModal(ProductPostModal, {});
    await modal.present();
  }

  async getHumidorHistoryModal(humidorId: string, productId?: number) {
    return this.modalService.createCSModal(HumidorHistoryModal, {
      productId,
      humidorId,
    });
  }

  async getCommentModal(params: {
    postId: number;
    focus?: boolean;
    scrollToCommentId?: number;
  }) {
    return this.modalService.createCSModal(SocialCommentModal, params, {
      common: {
        id: SocialCommentModal.modalId,
      },
      mobile: {
        handle: false,
        breakpoints: undefined,
      },
    });
  }

  async getCommentModalV2(params: {
    postId: number;
    text?: string;
    commentId?: number;
    commentParentId?: number;
    postAuthor?: IUserShort;
    replyTo?: IUserShort;
  }) {
    return this.modalService.createCSModal(SocialCommentV2Modal, params, {
      common: {
        id: SocialCommentV2Modal.modalId,
      },
      mobile: {
        handle: false,
        breakpoints: undefined,
      },
    });
  }

  async addFromCamera(addTo?: ICigarLogListType) {
    this.store.dispatch(userActions.SettingsRequest());

    try {
      const { image, product } = await this.cameraService.showCameraOptions(
        true
      );

      if (product) {
        const settings = await firstValueFrom(this.settings$.pipe(first()));
        if (addTo || settings?.AutoAddScanned) {
          this.store.dispatch(
            myCigarsActions.MyCigarsCreateRequestAction({
              ProductId: product.ProductId,
              LineId: product.LineId,
              listType: addTo || JOURNAL_LIST,
            })
          );
        } else {
          this.router.navigate([AppRoutes.cigar(getCigarCreatedId(product))]);
        }
        return;
      }

      const modal = await this.modalService.createCSModal(
        ScannerModal,
        {
          addTo,
          image,
        },
        {
          mobile: {
            handle: false,
            breakpoints: undefined,
          },
        }
      );
      await modal.present();
      const { data, role } =
        (await modal.onWillDismiss()) as ScannerModalResult;

      if (role === 'not-found') {
        this.openNotFound(data.scanLog, addTo);
      } else if (role === 'mutliple-results') {
        this.openAIResultsModal(data.scanLog, data.location, addTo);
      } else if (role === 'add-cigar') {
        this.addCigarToList(
          data.scanLog.originalImageUrl,
          data.scanLog.logId,
          data.cigar,
          data.addTo,
          data.location
        );
      } else if (role === 'add-and-go-to-cigar') {
        this.addCigarToList(
          data.scanLog.originalImageUrl,
          data.scanLog.logId,
          data.cigar,
          data.addTo,
          data.location
        );
        this.navigateToCigar(data.cigar, data.scanLog.logId);
      } else if (role === 'go-to-cigar') {
        this.navigateToCigar(data.cigar, data.scanLog.logId);
      } else {
        // this.toastService.show('No image found');
      }
    } catch (error) {
      console.log({ error });
      // error can be false here if cancelling the choice
      if (error) {
        this.toastService.show(getErrorMessage(error));
      }
    }
  }

  async addFromSearch(addTo?: ICigarLogListType) {
    try {
      const modal = await this.modalService.createCSModal(SearchModal, {
        addTo,
        title: addTo
          ? isListHumidor(addTo)
            ? 'Add to Humidor'
            : `Add to ${addTo}`
          : 'Search',
      });
      await modal.present();

      const { data, role } = (await modal.onWillDismiss()) as
        | {
            data: {
              LineId: number;
              ProductId: number;
            };
            role: 'submit';
          }
        | {
            data: string;
            role: 'goTo';
          };
      if (role === 'submit') {
        this.store.dispatch(
          myCigarsActions.MyCigarsCreateRequestAction({
            listType: addTo,
            ...data,
          })
        );
      } else if (role === 'goTo') {
        this.router.navigateByUrl(data);
      }
    } catch (error) {
      console.log({ error });
      // error can be false here if cancelling the choice
      if (error) {
        this.toastService.showError();
      }
    }
  }

  async openNotFound(scanLog: IScan, addTo?: ICigarLogListType) {
    const modal = await this.modalService.createCSModal(ScannerNoResultsModal, {
      scanLog,
    });
    await modal.present();

    const { data, role } =
      (await modal.onWillDismiss()) as ScannerNoResultsModalResult;

    if (role === 'camera') {
      this.addFromCamera(addTo);
    } else if (role === 'search') {
      this.addFromSearch(addTo);
    }
  }

  async openImageCropperModal(props: {
    blob: Blob;
    maintainAspectRatio?: boolean;
    aspectRatio?: number;
  }) {
    const modal = await this.modalService.createCSModal(
      ImageCropperModal,
      props,
      {
        mobile: {
          handle: false,
          breakpoints: undefined,
        },
      }
    );
    return modal;
  }

  async openLocationModal(props: { location: string }) {
    const modal = await this.modalService.createCSModal(LocationModal, props);
    return modal;
  }

  async openAIResultsModal(
    scanLog: IScan,
    location?: string,
    addTo?: ICigarLogListType
  ) {
    const modal = await this.modalService.createCSModal(ScannerAIResultsModal, {
      scanLog,
      addTo,
    });
    await modal.present();

    const { data, role } =
      (await modal.onWillDismiss()) as ScannerAIResultsModalResult;

    if (role === 'add-cigar') {
      this.addCigarToList(
        data.scanLog.originalImageUrl,
        null, // since the user selected the cigar manually, we don't need to pass the recognition id
        data.cigar,
        data.addTo,
        location
      );
    } else if (role === 'add-and-go-to-cigar') {
      this.addCigarToList(
        data.scanLog.originalImageUrl,
        null, // since the user selected the cigar manually, we don't need to pass the recognition id
        data.cigar,
        data.addTo,
        location
      );
      this.navigateToCigar(data.cigar);
    } else if (data?.cigar) {
      this.navigateToCigar(data.cigar);
    }
  }

  private addCigarToList(
    UserImageUrl: string,
    RecognitionId: number | null,
    cigar: ICigar,
    addTo?: ICigarLogListType,
    Location?: string
  ) {
    this.store.dispatch(
      myCigarsActions.MyCigarsCreateRequestAction({
        ProductId: cigar.ProductId,
        LineId: cigar.LineId,
        listType: addTo || JOURNAL_LIST,
        UserImageUrl,
        RecognitionId,
        Location,
      })
    );
  }

  private navigateToCigar(cigar: ICigar, recordId: number | null = null) {
    this.router.navigate([AppRoutes.cigar(getCigarCreatedId(cigar))], {
      queryParams: {
        recordId,
      },
    });
  }
}
