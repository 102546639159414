import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IState, IUserShort } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { CommentFormComponent } from '@shared/components/comment/comment.form';
import { CommentSkeletonComponent } from '@shared/components/comment/comment.skeleton.component';
import { IComment } from '@shared/models/comment-model';
import * as socialActions from '@store/actions/social';
import * as commentsSelectors from '@store/selectors/comments';
import * as socialSelectors from '@store/selectors/social.selectors';
import { addIcons } from 'ionicons';
import { closeOutline, send } from 'ionicons/icons';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, first, map, tap } from 'rxjs/operators';

import { SocialPostModel } from '../shared/models/social-post.model';
import { CommentContainer } from '@shared/components/comment/comment.container';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CommentSkeletonComponent,
    CommentFormComponent,
    CommentContainer,
    UserNamePipe,
    RxLet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonFooter,
    IonProgressBar,
  ],
  providers: [UserNamePipe],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title *rxLet="post$; let post"
          >{{ post?.Comments }}
          {{ post?.Comments > 1 ? 'comments' : 'comment' }}</ion-title
        >
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          w
          *ngIf="isPageLoading$ | async"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content #scrollElement>
      <div #contentWrapper>
        <ng-container *rxLet="post$; let post">
          <ng-container *rxLet="areCommentsLoading$; let isLoading">
            <ul class="p-0 pt-2 m-0 list-none" *ngIf="!isLoading">
              <social-post-comment-container
                *ngFor="
                  let comment of comments$ | async;
                  let i = index;
                  let last = last;
                  trackBy: trackByCommendId
                "
                [comment]="comment"
                [highlightedComment]="scrollToCommentId"
                [postAuthor]="post.User"
                [showMenu]="true"
                [hasMore]="comment.Comments > 0"
                (reply)="reply($event)"
              ></social-post-comment-container>
            </ul>

            <ion-list lines="full" *ngIf="isLoading">
              <social-post-skeleton-comment
                *ngFor="let comment of topCommentsSkeleton"
              ></social-post-skeleton-comment>
            </ion-list>
          </ng-container>
        </ng-container>
      </div>
    </ion-content>

    <ion-footer class="flex flex-col">
      <ion-toolbar mode="md" style="--min-height: none;" *ngIf="replyingTo">
        <ion-title class="text-sm">
          Replying to
          <strong>{{ replyingTo.User | userName }}</strong>
        </ion-title>
        <ion-button
          slot="end"
          fill="clear"
          type="button"
          (click)="removeReply()"
        >
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-toolbar>
      <ion-toolbar
        class="p-1 order-last !bottom-0 border-0 border-t border-solid border-gray-100  dark:border-gray-700"
      >
        <div class="flex gap-1 items-start">
          <comment-form
            class="text-sm"
            inputClass="!min-h-[50px]"
            #commentFormEl
            [isLoading]="isCommentLoading$ | async"
          ></comment-form>
          <ion-button
            fill="clear"
            size="default"
            type="button"
            (click)="submit()"
          >
            <ion-icon slot="icon-only" name="send"></ion-icon>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      ion-content {
      }
      ion-textarea {
        width: 100%;
      }
      comment-form {
        flex: 1;
        max-height: 30dvh;
        overflow: auto;
        @apply rounded;
        padding: 10px;
        margin-bottom: 5px;
      }
    `,
  ],
})
export class SocialCommentModal implements OnInit, OnDestroy {
  static modalId = 'comment-modal';
  @Input() postId: number;
  @Input() scrollToCommentId = null;
  @Input() focus = false;
  // isLoading = false;
  isCommenting = false;
  replyingTo: IComment | null = null;
  topComments: IComment[] | null = null;
  topCommentsSkeleton: null[] = [];
  @ViewChild('commentFormEl')
  commentFormEl: CommentFormComponent;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('contentWrapper', { static: false }) contentWrapper: ElementRef;
  routerSub: Subscription;

  post$: Observable<SocialPostModel>;
  comments$: Observable<IComment[]>;
  areCommentsLoading$: Observable<boolean>;
  isCommentLoading$: Observable<boolean>;
  isPageLoading$: Observable<boolean>;

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<IState>,
    private readonly actions$: Actions,
    private userNamePipe: UserNamePipe
  ) {
    addIcons({ closeOutline, send });
  }

  ngOnInit() {
    this.store.dispatch(
      socialActions.SocialPostLoadCommentsRequest({
        postId: this.postId,
      })
    );
    this.post$ = this.store.pipe(select(socialSelectors.getPost(this.postId)));
    this.comments$ = this.store.select(
      commentsSelectors.selectPostComments(this.postId)
    );
    this.areCommentsLoading$ = this.store
      .pipe(select(commentsSelectors.isThreadLoading(this.postId)))
      .pipe(
        tap((isLoading) => {
          console.log('isLoading', isLoading);

          if (!isLoading && this.scrollToCommentId) {
            setTimeout(
              () => this.scrollToCommentBottom(this.scrollToCommentId),
              500
            );
          }
        })
      );
    this.isCommentLoading$ = this.store.pipe(
      select(socialSelectors.isCommentLoading(this.postId))
    );
    this.isPageLoading$ = combineLatest([
      this.isCommentLoading$,
      this.areCommentsLoading$,
    ]).pipe(map(([loading1, loading2]) => loading1 || loading2));

    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.dismiss();
      });
  }

  trackByCommendId(index: number, comment: IComment) {
    return comment.Id;
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  ionViewDidEnter(): void {
    // this.loadComments();
    this.focus && this.commentFormEl.focus();
  }

  removeReply() {
    this.replyingTo = null;
    this.commentFormEl.focusAndCursorToEnd();
    this.cdr.markForCheck();
  }

  reply(comment: IComment) {
    this.replyingTo = comment;
    this.commentFormEl.initText(
      `##${comment.User.UserId}:${this.userNamePipe.transform(comment.User)}##`
    );
    this.commentFormEl.focusAndCursorToEnd();
    this.scrollToCommentBottom(comment.Id);
    this.cdr.markForCheck();
  }

  async scrollToCommentBottom(commentId: number) {
    const scrollElement = await this.content.getScrollElement(); // Get the scrollable element
    const element = document.getElementById(`comment-${commentId}`);
    if (element && scrollElement) {
      const scrollContentHeight = scrollElement.offsetHeight;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight;

      this.content.scrollToPoint(
        0,
        elementOffsetTop - scrollContentHeight + elementHeight,
        500
      );
    }
  }

  // loadComments() {
  //   this.isLoading = true;
  //   this.topCommentsSkeleton = Array(this.post?.Comments || 0).fill(null);
  //   this.cdr.markForCheck();
  //   this.socialService
  //     .getComments(this.postId)
  //     .pipe(
  //       tap({
  //         next: (comments) => (this.topComments = comments),
  //       }),
  //       finalize(() => {
  //         this.isLoading = false;
  //         this.cdr.markForCheck();
  //       })
  //     )
  //     .subscribe();
  // }

  submit() {
    this.isCommenting = true;
    if (this.commentFormEl.formGroup.invalid) {
      this.commentFormEl.formGroup.markAllAsTouched();
      return;
    }
    const { text } = this.commentFormEl.formGroup.value;
    this.cdr.markForCheck();
    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostAddCommentSuccess,
          socialActions.SocialPostAddCommentError
        ),
        first(),
        finalize(() => {
          this.isCommenting = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(({ type }) => {
        if (type === socialActions.SocialPostAddCommentSuccess.type) {
          this.commentFormEl.initText('');
          this.replyingTo = null;
          this.cdr.markForCheck();
        }
      });
    if (this.replyingTo) {
      this.store.dispatch(
        socialActions.SocialPostAddCommentRequest({
          postId: this.postId,
          // this is to keep only 2 levels for now
          commentParentId: this.replyingTo.ParentId
            ? this.replyingTo.ParentId
            : this.replyingTo.Id,
          text,
        })
      );
    } else {
      // reply to post
      this.store.dispatch(
        socialActions.SocialPostAddCommentRequest({
          postId: this.postId,
          text,
        })
      );
    }
  }

  dismiss() {
    this.modalService.dismiss(
      {
        dismissed: true,
      },
      undefined,
      SocialCommentModal.modalId
    );
  }
}
