import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ICigar, ICigarLogListType, IScan, IState } from '@models';
import { select, Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { RecognitionService } from '@services/recognition.service';
import { CigarListEnum } from '@shared/models/cigar-log.model';
import { PictureModel } from '@shared/models/picture.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { LocationService } from '@shared/services/location.service';
import { ScanCigarService } from '@shared/services/scan-cigar.service';
import * as userSelectors from '@store/selectors/user';
import debug from 'debug';
import { fabric } from 'fabric';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';
import { first, firstValueFrom } from 'rxjs';

const log = debug('cs:ScannerModal');

export type ScannerModalResult =
  | {
      data: {
        scanLog: IScan;
      };
      role: 'not-found';
    }
  | {
      data: {
        scanLog: IScan;
        location?: string;
      };
      role: 'mutliple-results';
    }
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
        addTo: string;
        location?: string;
      };
      role: 'add-cigar';
    }
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
      };
      role: 'go-to-cigar';
    }
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
        addTo: string;
        location?: string;
      };
      role: 'add-and-go-to-cigar';
    }
  | {
      data: null;
      role: 'cancel';
    }
  | {
      data: null;
      role: 'no-image';
    };

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonProgressBar,
    IonContent,
    IonIcon,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Crop Cigar</ion-title>
        <ion-buttons slot="end">
          <ion-button
            fill="outline"
            shape="round"
            color="primary"
            (click)="submit()"
            [strong]="true"
            [autofocus]="false"
            [disabled]="isLoading"
          >
            Submit
          </ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isLoading"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>
    <ion-content #content [scrollY]="false" [scrollX]="false">
      <canvas #canvasEl class="w-full h-full"></canvas>
    </ion-content>
  `,
})
export class ScannerModal implements OnInit {
  @Input() image: PictureModel;
  @Input() addTo?: ICigarLogListType;
  isLoading = false;
  fabric: any;
  canvas: fabric.Canvas;
  outline: fabric.Rect;
  defaultOverlaySize = 0.4;
  settings$ = this.store.pipe(select(userSelectors.selectSettings));
  @ViewChild('content', { static: true }) content: IonContent;
  @ViewChild('canvasEl', { static: false })
  canvasEl: ElementRef<HTMLCanvasElement>;

  constructor(
    private alertCtrl: AlertController,
    private scanCigarService: ScanCigarService,
    private locationService: LocationService,
    private analyticsService: AnalyticsService,
    private recognitionService: RecognitionService,
    private modalService: ModalService,
    private store: Store<IState>
  ) {
    addIcons({ close });
  }

  getFabric() {}

  ngOnInit(): void {
    if (!this.image) {
      this.modalService.dismiss(null, 'no-image');
      return;
    }
  }

  async ionViewDidEnter() {
    const scrollElement = await this.content.getScrollElement();

    const containerWidth = scrollElement.clientWidth;
    const containerHeight = scrollElement.clientHeight;
    const imageAspectRatio = this.image.width / this.image.height;
    const containerAspectRatio = containerWidth / containerHeight;

    log('ionViewDidEnter image', {
      image: this.image,
      size: `${this.image.file ? this.image.file.size / 1000000 : 'unknown'}mb`,
      addTo: this.addTo,
      imageAspectRatio,
      containerAspectRatio,
    });
    let width = 0;
    let height = 0;

    if (imageAspectRatio > containerAspectRatio) {
      width = containerWidth;
      height = containerWidth / imageAspectRatio;
    } else {
      height = containerHeight;
      width = containerHeight * imageAspectRatio;
    }

    this.canvas = new fabric.Canvas(this.canvasEl.nativeElement, {
      selection: false,
      uniformScaling: true,
      height,
      width,
    });
    this._scanPicture();
  }

  dismiss() {
    this.modalService.dismiss(null, 'cancel');
  }

  private async _scanPicture() {
    let coords = await firstValueFrom(
      this.scanCigarService.scanPicture(this.image.data)
    );
    log('_scanPicture image', this.image);
    log(
      `_scanPicture image size:  ${
        this.image.file ? this.image.file.size / 1000000 : 'unknown'
      }mb`
    );

    this.canvas.setBackgroundImage(
      this.image.data,
      () => {
        if (
          coords.right - coords.left <= 0 ||
          coords.bottom - coords.top <= 0
        ) {
          coords = this._defaultOverlayCoords();
        }

        this.outline = new fabric.Rect({
          left: this._applyRatio(coords.left),
          top: this._applyRatio(coords.top),
          originX: 'left',
          originY: 'top',
          width: this._applyRatio(coords.width),
          height: this._applyRatio(coords.height),
          angle: coords.angle,
          fill: 'rgba(255, 184, 6, 0.25)',
          borderColor: '#FFB806',
          cornerColor: '#FFB806',
          cornerSize: 26,
          transparentCorners: false,
          selectable: true,
        });

        this.canvas.add(this.outline);
        this.canvas.setActiveObject(this.outline);
      },
      {
        scaleX: this.canvas.width / this.image.width,
        scaleY: this.canvas.height / this.image.height,
        originX: 'left',
        originY: 'top',
      }
    );
  }

  private _applyRatio(val) {
    return (this.canvas.width / this.image.width) * val;
  }

  async submit() {
    this.isLoading = true;
    this.canvas.interactive = false;
    this.outline.selectable = false;
    this.analyticsService.photoScanAttempt();
    log('submit image', this.image);

    const obs$ = await this.recognitionService.submitPicture(
      this.image,
      this.outline.angle,
      Math.floor(this.outline.left / (this.canvas.width / this.image.width)),
      Math.floor(this.outline.top / (this.canvas.width / this.image.width)),
      Math.floor(
        this.outline.getScaledWidth() / (this.canvas.width / this.image.width)
      ),
      Math.floor(
        this.outline.getScaledHeight() / (this.canvas.width / this.image.width)
      )
    );
    obs$.subscribe(
      async (scan: IScan) => {
        log('scan results', scan);
        const settings = await firstValueFrom(this.settings$.pipe(first()));
        const location = await this.getLocation();
        if (!scan.results || scan.results.length === 0) {
          this.analyticsService.photoScanNotFound();
          this.notFound(scan);
          return;
        }
        this.analyticsService.photoScanFound();

        const isProduct = !!scan.data.ProductId;
        const isLine = !isProduct;

        if (scan.results.length > 1) {
          this.openMultipleResultsModal(scan, location);
        } else {
          const cigar = scan.results[0].Cigar;
          const isProduct = !!cigar.ProductId;
          const isLine = !isProduct;
          if (isLine) {
            this.openMultipleResultsModal(scan, location);
          } else if (this.addTo) {
            this.addCigar(scan, cigar, this.addTo, location);
          } else if (settings?.AutoAddScanned) {
            this.addAndGoToCigar(
              scan,
              cigar,
              CigarListEnum.JOURNAL_LIST,
              location
            );
          } else {
            this.goTo(scan, cigar);
          }
        }
      },
      (error: HttpErrorResponse) => {
        log({ error });
        this.analyticsService.photoScanFailed();
        this.isLoading = false;
        this.canvas.interactive = true;
        this.outline.selectable = true;
        this.alertCtrl
          .create({
            header: 'Error occurred',
            subHeader: 'Image recognition failed',
            buttons: ['OK'],
          })
          .then((alert) => {
            alert.present();
            this.dismiss();
          });
      }
    );
  }

  private async getLocation() {
    try {
      return this.locationService.getImageLocationAddress(this.image);
    } catch (error) {}
    return null;
  }

  private _defaultOverlayCoords() {
    const height = this.image.height * this.defaultOverlaySize;
    const width = this.image.width * this.defaultOverlaySize;
    const verticalCenter = this.image.height / 2;
    const horizontalCenter = this.image.width / 2;

    return {
      angle: 0,
      top: verticalCenter - height / 2,
      bottom: verticalCenter + height / 2,
      left: horizontalCenter - width / 2,
      right: horizontalCenter + width / 2,
      width: width,
      height: height,
    };
  }

  private async notFound(scanLog: IScan) {
    this.modalService.dismiss({ scanLog }, 'not-found');
  }

  private async addCigar(
    scanLog: IScan,
    cigar: ICigar,
    addTo: string,
    location?: string
  ) {
    log('Cigar Found', { scanLog, cigar, location });
    this.modalService.dismiss({ scanLog, cigar, addTo, location }, 'add-cigar');
  }

  private async openMultipleResultsModal(scanLog: IScan, location?: string) {
    log('Line Found', { scanLog, location });
    this.modalService.dismiss({ scanLog, location }, 'mutliple-results');
  }

  private async goTo(scanLog: IScan, cigar: ICigar) {
    this.modalService.dismiss(
      {
        scanLog,
        cigar,
      },
      'go-to-cigar'
    );
  }

  private async addAndGoToCigar(
    scanLog: IScan,
    cigar: ICigar,
    addTo: string,
    location?: string
  ) {
    this.modalService.dismiss(
      {
        scanLog,
        cigar,
        addTo,
        location,
      },
      'add-and-go-to-cigar'
    );
  }
}
