import { ICommentsState } from '@models';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';

import { adapter, NAMESPACE } from '../reducers/comments';
import { getPost } from './social.selectors';

export const selectCommentsState =
  createFeatureSelector<ICommentsState>(NAMESPACE);

const { selectAll, selectEntities } = adapter.getSelectors(selectCommentsState);

export const selectComments = selectAll;

export const selectIsThreadLoading = createSelector(
  selectCommentsState,
  (state: ICommentsState) => state.isThreadLoading
);

export const isThreadLoading = (postId: number, parentId?: number) => {
  const loadingId = parentId ? `${postId}:${parentId}` : `${postId}`;
  return createSelector(selectIsThreadLoading, (isThreadLoading) => {
    return isThreadLoading[loadingId] || false;
  });
};

export const selectComment = (Id: number) => {
  return createSelector(selectEntities, (entities) => {
    return entities[Id];
  });
};

// export const selectPostCommentIds = (postId: number) => {
//   return createSelector(selectPostsRecord, (state) => {
//     return state[postId] || [];
//   });
// };

export const selectPostComments = (postId: number) => {
  return createSelector(getPost(postId), selectEntities, (post, entities) => {
    return createCommentTree(post.CommentIds || [], entities);
  });
};

function createCommentTree(
  levelIds: number[],
  entities: Dictionary<IComment>,
  level = 0
): IComment[] {
  return levelIds
    .map((id) => {
      const comment = entities[id];
      if (!comment) return null;
      return {
        ...entities[id],
        ThreadComments: createCommentTree(
          comment.ThreadCommentIds || [],
          entities,
          level + 1
        ),
      };
    })
    .filter((comment) => !!comment)
    .sort((a, b) =>
      level === 0
        ? new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
        : new Date(a.CreatedOn).getTime() - new Date(b.CreatedOn).getTime()
    );
}
