import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import {
  Camera,
  CameraPermissionType,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { environment } from '@environments/environment';
import Quagga from '@ericblade/quagga2';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  Platform,
} from '@ionic/angular/standalone';
import { ActionSheetButton } from '@ionic/core';
import { ICigar } from '@models';
import { ProductService } from '@services/product.service';
import { ScannerService } from '@services/scanner/scanner.service';
import { DeviceService } from '@shared/services/device.service';
import { validateEAN13, validateGTIN14, validateUPC } from '@utils/cigar';
import debug from 'debug';
import * as ExifReader from 'exifreader';
import { firstValueFrom } from 'rxjs';

import { PictureModel } from '../models/picture.model';
import { dataURLtoBlob } from '../utils';
import { AnalyticsService } from './analytics.service';
import { ImageResizeService } from './image-resize.service';

// import { BrowserMultiFormatOneDReader } from '@zxing/browser';
// import { BarcodeFormat, DecodeHintType } from '@zxing/library';
declare var window: any;
const log = debug('cs:CameraService');

export enum CameraActionSheetRoles {
  NATIVE_PICTURE = 'native_picture',
  NATIVE_UPLOAD = 'native_upload',
  WEB_PICTURE = 'web_picture',
  SCANNER = 'scanner',
}

const allowedErrors = ['User cancelled photos app', 'No image picked'];

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  private latest: PictureModel;
  private imageMaxSize = 2000;
  private imageQuality = 60;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private imageResizeService: ImageResizeService,
    private productService: ProductService,
    private analyticsService: AnalyticsService,
    private platform: Platform,
    private scannerService: ScannerService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private deviceService: DeviceService,
    private alertController: AlertController
  ) {}

  isNativeCameraEnabled(type: CameraPermissionType) {
    return new Promise(function (resolve, reject) {
      Camera.checkPermissions()
        .then((data) => {
          switch (data[type]) {
            case 'prompt':
            case 'prompt-with-rationale':
            case 'denied':
              Camera.requestPermissions({
                permissions: [type],
              })
                .then((data) => {
                  switch (data[type]) {
                    case 'granted':
                      resolve(true);
                      break;
                    default:
                      reject(
                        `${type} access denied, please enable it in settings`
                      );
                      break;
                  }
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 'granted':
              resolve(true);
              break;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private async _cameraPicture2(source: CameraSource) {
    const options: ImageOptions = {
      resultType: CameraResultType.DataUrl,
      source,
      correctOrientation: true,
      width: this.imageMaxSize,
      height: this.imageMaxSize,
      quality: this.imageQuality,
      allowEditing: false,
      webUseInput: true,
    };

    const test = Camera.getPhoto(options);
    const { dataUrl } = await test;
    log('_cameraPicture2', { options, dataUrl });
    // const { dataUrl } = await Camera.getPhoto(options);
    const file = dataURLtoBlob(dataUrl, environment.maxImageUploadSize);
    log('_cameraPicture2', { file });
    const location = await this._readLocationFromImage2(file);
    log('_cameraPicture2', options, { dataUrl, file, location });
    return { dataUrl, file, location };
  }

  private _readLocationFromImage2(
    file
  ): Promise<{ latitude: number; longitude: number } | undefined> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const tags = ExifReader.load(reader.result as ArrayBuffer);

          if (
            tags.hasOwnProperty('GPSLatitude') &&
            tags.hasOwnProperty('GPSLongitude')
          ) {
            const latSign = tags.GPSLatitudeRef.value[0] === 'N' ? 1 : -1;
            const lngSign = tags.GPSLongitudeRef.value[0] === 'E' ? 1 : -1;

            const latitude = parseFloat(tags.GPSLatitude.description) * latSign;
            const longitude =
              parseFloat(tags.GPSLongitude.description) * lngSign;

            if (isNaN(latitude) || isNaN(longitude)) {
              res(undefined);
            }

            res({
              latitude,
              longitude,
            });
          }
          res(undefined);
        } catch (error) {
          rej(error);
        }
      };

      // We only need the start of the file for the Exif info.
      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
    });
  }

  private _createModel2(
    data: string,
    file: Blob,
    location?: { latitude: number; longitude: number }
  ): Promise<PictureModel> {
    return new Promise((res, rej) => {
      const image = new Image();
      image.onload = () => {
        const picture = new PictureModel({
          file,
          data: data,
          width: image.width,
          height: image.height,
          location,
        });
        this._saveToLatest(picture);
        res(picture);
      };
      image.onerror = (error) => {
        rej(error);
      };
      image.src = data;
    });
  }

  private _saveToLatest(picture: PictureModel) {
    this.latest = picture;
  }

  getLatest() {
    return this.latest;
  }

  public async getBarcode() {
    const { dataUrl, file, location } = await this._cameraPicture2(
      CameraSource.Camera
    );

    const result = await Quagga.decodeSingle({
      src: dataUrl,
      numOfWorkers: 4,
      locate: true,
      inputStream: {
        size: 800, // restrict input-size to be 800px in width (long-side)
      },
      decoder: {
        readers: [
          // 'code_128_reader',
          // 'code_128_reader',
          'ean_reader',
          // 'ean_5_reader',
          // 'ean_2_reader',
          // 'ean_8_reader',
          // 'code_39_reader',
          // 'code_39_vin_reader',
          // 'codabar_reader',
          'upc_reader',
          'upc_e_reader',
          // 'i2of5_reader',
          // '2of5_reader',
          // 'code_93_reader',
          // 'code_32_reader',
        ], // List of active readers
      },
    });

    if (result && result.codeResult) {
      return result.codeResult.code;
    }
    return '';
  }

  getActionSheetButtons(
    platform: 'native' | 'web',
    res: (ok: { image: PictureModel }) => void,
    rej: (reason?: string | false) => void
  ): ActionSheetButton[] {
    const menu: (ActionSheetButton & { platform: 'native' | 'web' })[] = [
      {
        text: 'Take Picture',
        role: CameraActionSheetRoles.NATIVE_PICTURE,
        platform: 'native',
        handler: async () => {
          try {
            await this.isNativeCameraEnabled('camera');
            const { dataUrl, file, location } = await this._cameraPicture2(
              CameraSource.Camera
            );
            const image = await this._createModel2(dataUrl, file, location);
            res({ image });
          } catch (error) {
            log(CameraActionSheetRoles.NATIVE_PICTURE, error.message);
            if (allowedErrors.includes(error.message)) {
              rej(false);
            }
            rej(error);
          }
        },
      },
      {
        text: 'Upload Picture',
        role: CameraActionSheetRoles.NATIVE_UPLOAD,
        platform: 'native',
        handler: async () => {
          try {
            await this.isNativeCameraEnabled('photos');
            const { dataUrl, file, location } = await this._cameraPicture2(
              CameraSource.Photos
            );
            const image = await this._createModel2(dataUrl, file, location);
            res({ image });
          } catch (error) {
            log(CameraActionSheetRoles.NATIVE_UPLOAD, error.message);
            if (allowedErrors.includes(error.message)) {
              rej(false);
            }
            rej(error);
          }
        },
      },
      {
        text: 'Take Picture',
        role: CameraActionSheetRoles.WEB_PICTURE,
        platform: 'web',
        handler: async () => {
          try {
            const { file, location } = await this._cameraPicture2(
              CameraSource.Prompt
            );
            const { blob, base64 } =
              await this.imageResizeService.resizePWAImage(
                file,
                this.imageMaxSize,
                this.imageQuality
              );
            const image = await this._createModel2(base64, blob, location);
            res({ image });
          } catch (error) {
            log(CameraActionSheetRoles.WEB_PICTURE, error.message);
            if (allowedErrors.includes(error.message)) {
              rej(false);
            }
            if (error.message === 'No Exif data') {
              error.message = 'Cannot open that file. missing Exif data';
            }
            rej(error);
          }
        },
      },
    ];
    return menu.filter((item) => item.platform === platform);
  }

  async showCameraOptions(showScanner = false): Promise<{
    product?: ICigar;
    image?: PictureModel;
  }> {
    // this is wrapper in a Promise for Safari to work
    return new Promise(async (res, rej) => {
      const buttons = this.getActionSheetButtons(
        this.platform.is('capacitor') ? 'native' : 'web',
        res,
        rej
      );

      if (showScanner) {
        buttons.push({
          text: 'Scan Barcode',
          role: CameraActionSheetRoles.SCANNER,
        });
      }

      const actionSheet = await this.actionSheetCtrl.create({
        buttons: [
          ...buttons,
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => rej(false),
          },
        ],
      });
      await actionSheet.present();
      const { data, role } = await actionSheet.onWillDismiss();
      if (role === CameraActionSheetRoles.SCANNER) {
        try {
          this.analyticsService.barcodeScanAttempt();
          let codebar = '';
          if (this.platform.is('ios') && this.deviceService.isStandalone) {
            codebar = await this.getBarcode();
          } else {
            try {
              codebar = await this.scannerService.start();
            } catch (error) {
              if (error.message === 'NOT_GRANTED') {
                const alert = await this.alertController.create({
                  header: 'The app needs camera access',
                  message:
                    'Please enable it in the settings or use the manual input.',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                    },
                    {
                      text: 'Open Settings',
                      handler: () => {
                        BarcodeScanner.openSettings();
                      },
                    },
                  ],
                });
                await alert.present();
              }
              return;
            }
          }

          try {
            // bypasse error handling the first attempt
            if (!codebar) {
              throw new Error('No barcode provided');
            }
            const product = await this.getProductFromScan(codebar);
            this.analyticsService.barcodeScanFound(product.Name);
            res({
              product,
            });
          } catch (error) {
            console.log({ error });
            const newCode = await this.getManualUPC(codebar);
            const product = await this.getProductFromScan(newCode);
            this.analyticsService.barcodeScanFound(product.Name);
            res({
              product,
            });
          }
        } catch (error) {
          if (error.message === 'User cancelled') {
            rej(false);
          }
          rej(error);
        }
      }
    });
  }

  getSearchCigarLoader() {
    return this.loadingCtrl.create({
      keyboardClose: false,
      message: 'Looking for the cigar...',
    });
  }

  async getManualUPC(value: string) {
    const alert = await this.alertCtrl.create({
      header: 'Could not find the cigar',
      subHeader: value
        ? 'Please verify if the UPC code is correct'
        : 'Please enter the UPC code',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Submit',
          role: 'submit',
        },
      ],
      inputs: [
        {
          type: 'text',
          value,
          placeholder: 'UPC',
        },
      ],
    });

    await alert.present();
    const { data, role } = await alert.onWillDismiss();
    if (role === 'submit' && data.values[0]) {
      return data.values[0];
    }
    throw new Error('User cancelled');
  }

  private async getProductFromScan(text: string) {
    if (!text) {
      this.analyticsService.barcodeScanFailed();
      throw new Error('No barcode provided');
    }
    if (
      text.length < 12 ||
      !(validateUPC(text) || validateEAN13(text) || validateGTIN14(text))
    ) {
      throw new Error('Invalid barcode');
    }
    const loader = await this.getSearchCigarLoader();
    await loader.present();
    try {
      const productId = await firstValueFrom(
        this.productService.getIdByUpc(text)
      );
      if (!productId) {
        this.analyticsService.barcodeScanNotFound(text);
        throw new Error('Unknown barcode');
      }
      const value = await firstValueFrom(
        this.productService.getProduct(Number(productId))
      );
      await loader.dismiss();
      return value;
    } catch (error) {
      await loader.dismiss();
      throw error;
    }
  }
}
