import { AppRoutes } from '@utils/routes';
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { commentAtRegex, hashtagRegex } from '@utils/regex';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'commentBeautify',
  standalone: true,
})
export class CommentBeautifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(string) {
    try {
      return this.replaceHashTagsWithSpanElements(string);
    } catch (error) {
      console.error(error);
      return string;
    }
  }

  replaceHashTagsWithSpanElements(string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      // remove any html tags
      DOMPurify.sanitize(string, { USE_PROFILES: { html: false } })
        .replace(commentAtRegex, (match, id, label) => {
          return `<a routerlink="${AppRoutes.socialUser(
            'users',
            id
          )}">${label}</a>`;
        })
        // .replace(hashtagRegex, (match) => {
        //   const hashtag = match.slice(1);
        //   return `<a routerlink="${AppRoutes.socialSearch(
        //     'post',
        //     hashtag
        //   )}">${match}</a>`;
        // })
        .replace(/\n/g, '<br>')
    );
  }
}
