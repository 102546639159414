import { Injectable } from '@angular/core';
import ExifReader from 'exifreader';

@Injectable({
  providedIn: 'root',
})
export class ImageResizeService {
  resizePWAImage(
    file: Blob,
    maxSize: number,
    quality: number
  ): Promise<{ blob: Blob; base64: string }> {
    const reader = new FileReader();
    const image = new Image();

    return new Promise((resolve, reject) => {
      if (!file.type.match(/image.*/)) {
        reject(new Error('Not an image'));
        return;
      }

      reader.onload = async () => {
        image.onload = async () => {
          const orientation = await this.readOrientationFromImage(file);
          const [base64, blob] = await this._resize(
            image,
            file.type,
            maxSize,
            quality,
            orientation
          );
          resolve({ base64, blob });
        };
        image.src = reader.result as string;
      };

      reader.readAsDataURL(file);
    });
  }

  /**
   * Remove the code that rotates the images as it seems not to be necessary
   */
  private async _resize(
    image: HTMLImageElement,
    mimeType: string,
    maxSize,
    quality,
    orientation
  ): Promise<[string, Blob]> {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let width = image.width;
    let height = image.height;

    if (width > height && width > maxSize) {
      // landscape
      height *= maxSize / width;
      width = maxSize;
    } else if (height > maxSize) {
      // portrait
      width *= maxSize / height;
      height = maxSize;
    }

    // if (4 < orientation && orientation < 9) {
    //   canvas.width = height;
    //   canvas.height = width;
    // } else {
    canvas.width = width;
    canvas.height = height;
    // }

    // switch (orientation) {
    //   case 2:
    //     ctx.transform(-1, 0, 0, 1, width, 0);
    //     break;
    //   case 3:
    //     ctx.transform(-1, 0, 0, -1, width, height);
    //     break;
    //   case 4:
    //     ctx.transform(1, 0, 0, -1, 0, height);
    //     break;
    //   case 5:
    //     ctx.transform(0, 1, 1, 0, 0, 0);
    //     break;
    //   case 6:
    //     ctx.transform(0, 1, -1, 0, height, 0);
    //     break;
    //   case 7:
    //     ctx.transform(0, -1, -1, 0, height, width);
    //     break;
    //   case 8:
    //     ctx.transform(0, -1, 1, 0, 0, width);
    //     break;
    //   default:
    //     break;
    // }

    ctx.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL(mimeType, quality / 100);
    const blob = await this.getBlogFromCanvas(canvas, mimeType, quality / 100);
    return [dataUrl, blob];
  }

  private getBlogFromCanvas(
    canvas: HTMLCanvasElement,
    type = 'image/jpeg',
    quality = 1 // 1 being the best
  ): Promise<Blob> {
    return new Promise((res, rej) => {
      try {
        canvas.toBlob(
          (blob) => {
            res(blob);
          },
          type,
          quality
        );
      } catch (error) {
        rej(error);
      }
    });
  }

  private readOrientationFromImage(image): Promise<number> {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const tags = ExifReader.load(reader.result as ArrayBuffer);
          res(tags.Orientation.value);
        } catch (error) {
          res(null);
        }
      };

      // We only need the start of the file for the Exif info.
      reader.readAsArrayBuffer(image.slice(0, 128 * 1024));
    });
  }
}
