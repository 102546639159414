import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  IonButton,
  IonIcon,
  PopoverController,
} from '@ionic/angular/standalone';
import { IUserShort } from '@models/user';
import { RxLet } from '@rx-angular/template/let';
import { DynamicRouterDirective } from '@shared/directives/dynamic-router.directive';
import { IComment } from '@shared/models/comment-model';
import { AgoPipe } from '@shared/pipes/ago.pipe';
import { CommentBeautifyPipe } from '@shared/pipes/comment-beautify.pipe';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { NumeralPipe } from '@shared/pipes/numeral.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { getUserTypeAndId } from '@utils/users';
import { addIcons } from 'ionicons';
import {
  chatbubbleOutline,
  ellipsisVertical,
  heart,
  heartOutline,
} from 'ionicons/icons';

import { CommentSkeletonComponent } from './comment.skeleton.component';
import {
  CommentActionsPopover,
  CommonMenuAction,
} from './comments-actions.popover';

@Component({
  selector: 'social-post-comment',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RxLet,
    IonButton,
    IonIcon,
    CreateSourceStandalonePipe,
    UserNamePipe,
    CommentBeautifyPipe,
    AgoPipe,
    NumeralPipe,
    DynamicRouterDirective,
    CommentSkeletonComponent,
  ],
  template: `
    <li
      class=""
      [ngClass]="{
        'bg-indigo-200 dark:bg-indigo-600': isHighlighted,
        'border-0 border-b-[3px] border-solid border-b-gray-200 dark:border-b-gray-800':
          !comment.ParentId
      }"
    >
      <div [id]="'comment-' + comment.Id" class="relative px-3 pt-3">
        <span
          *ngIf="hasMore"
          class="absolute left-8 top-8 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600"
          aria-hidden="true"
        ></span>
        <div class="relative flex items-start space-x-3">
          <div class="relative">
            <img
              class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
              loading="lazy"
              (click)="navigateToUserProfile(comment.User)"
              [src]="comment.User.AvatarUrl | createSource"
              src-fallback="assets/images/user-image.png"
            />
          </div>
          <div class="min-w-0 flex-1">
            <div class="flex flex-row" (click)="commentClick.emit()">
              <div class="flex-1">
                <div class="text-sm font-medium">
                  {{ comment.User | userName }}
                </div>
                <p
                  class="m-0 mt-0.5 text-xs text-gray-500 dark:text-gray-300 font-medium"
                >
                  {{ comment.CreatedOn | ago | async }}
                </p>
              </div>

              <ion-button
                *ngIf="showMenu"
                slot="end"
                color="dark"
                fill="clear"
                (click)="openActions($event)"
              >
                <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
              </ion-button>
            </div>
            <div class="mt-2 text-sm" (click)="commentClick.emit()">
              <p
                class="!font-medium m-0"
                appDynamicRouter
                [innerHTML]="comment.Text | commentBeautify"
              ></p>
            </div>
            <div
              class="flex gap-2 border-0 border-t border-gray-100 border-solid dark:border-gray-600 mt-2"
            >
              <ion-button
                mode="md"
                fill="clear"
                size="small"
                (click)="reply.emit(comment)"
                color="medium"
              >
                <ion-icon slot="icon-only" name="chatbubble-outline"></ion-icon>
              </ion-button>
              <ion-button
                mode="md"
                fill="clear"
                size="small"
                [disabled]="comment.isLoading"
                (click)="toggleCommentLike.emit(comment)"
                [color]="comment.Liked ? 'primary' : 'medium'"
              >
                <ion-icon
                  style="font-size: 18px"
                  slot="icon-only"
                  [name]="comment.Liked ? 'heart' : 'heart-outline'"
                ></ion-icon>
                <span class="ml-1" *ngIf="comment.Likes">
                  {{ comment.Likes | numeral }}
                </span>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
      <ng-content></ng-content>

      <!-- <social-post-comment
        *ngFor="
          let commentLevel2 of comment.ThreadComments
            | slice
              : 0
              : (!isThreadLoading && threadExpended
                  ? maxExpendedThreadLength
                  : 1);
          let i = index;
          let last = last;
          trackBy: trackByCommendId
        "
        [comment]="commentLevel2"
        [isHighlighted]="isHighlighted"
        [isOwner]="isOwner"
        (editComment)="editComment.emit($event)"
        (deleteComment)="deleteComment.emit($event)"
        (reportComment)="reportComment.emit($event)"
        (toggleCommentLike)="toggleCommentLike.emit($event)"
        (reply)="reply.emit($event)"
        (blockUser)="blockUser.emit($event)"
        (commentClick)="commentClick.emit()"
        [hasMore]="last ? comment.ThreadComments.length > i + 1 : true"
      >
      </social-post-comment> -->
    </li>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ion-item ion-item {
        --padding-start: 0;
      }
      ion-label {
        max-width: none !important;
        width: 100%;
      }
    `,
  ],
})
export class CommentComponent {
  @Input() comment: IComment;
  @Input() isHighlighted = false;
  @Input() isOwner = false;
  @Input() showMenu = true;
  @Input() hasMore = true;
  // @Input() isLoading = false;
  @Output() commentClick = new EventEmitter<void>();
  @Output() edit = new EventEmitter<string>();
  @Output() editComment = new EventEmitter<IComment>();
  @Output() toggleCommentLike = new EventEmitter<IComment>();
  @Output() reply = new EventEmitter<IComment>();
  @Output() deleteComment = new EventEmitter<IComment>();
  @Output() reportComment = new EventEmitter<{
    commentId: number;
    reasonId: number;
  }>();
  @Output() blockUser = new EventEmitter<{
    commentId: number;
    user: IUserShort;
  }>();

  constructor(public popoverCtrl: PopoverController, private router: Router) {
    addIcons({ ellipsisVertical, chatbubbleOutline, heartOutline, heart });
  }

  trackByCommendId(index: number, comment: IComment) {
    return comment.Id;
  }

  public navigateToUserProfile(user: IUserShort) {
    const [userType, id] = getUserTypeAndId(user);
    this.router.navigate(['tabs', 'social', userType, id]);
  }

  async openActions(ev: Event) {
    ev.stopPropagation();
    const popover = await this.popoverCtrl.create({
      component: CommentActionsPopover,
      event: ev,
      translucent: true,
      componentProps: {
        isOwner: this.isOwner,
      },
    });
    await popover.present();
    const { role, data } = (await popover.onWillDismiss()) as CommonMenuAction;
    switch (role) {
      case 'edit':
        this.editComment.emit(this.comment);
        break;
      case 'delete':
        this.deleteComment.emit(this.comment);
        break;
      case 'report':
        this.reportComment.emit({
          commentId: this.comment.Id,
          reasonId: data.reasonId,
        });
        break;
      case 'block':
        this.blockUser.emit({
          commentId: this.comment.Id,
          user: this.comment.User,
        });
        break;
    }
  }
}
