import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonList,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IState, IUserShort } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { CommentFormComponent } from '@shared/components/comment/comment.form';
import { CommentModule } from '@shared/components/comment/component.module';
import { IComment } from '@shared/models/comment-model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import * as socialActions from '@store/actions/social';
import * as socialSelectors from '@store/selectors/social.selectors';
import * as userSelectors from '@store/selectors/user';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { Observable } from 'rxjs';
import { filter, finalize, first } from 'rxjs/operators';

import { SocialPostModel } from '../shared/models/social-post.model';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    CommentModule,
    RxLet,
    CreateSourceStandalonePipe,
    UserNamePipe,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonProgressBar,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ commentId ? 'Edit' : '' }}</ion-title>
        <ion-buttons slot="end">
          <ion-button
            [disabled]="commentFormEl.formGroup.invalid"
            (click)="submit()"
            color="dark"
          >
            {{ commentId ? 'Save' : 'Reply' }}
          </ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isCommentLoading$ | async"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding-horizontal">
      <div class="relative pb-3">
        <span
          class="absolute left-5 top-0 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600"
          aria-hidden="true"
        ></span>
        <div class="relative flex items-start space-x-3 pt-2">
          <div class="relative w-10"></div>
          <div class="min-w-0 flex-1" *ngIf="postAuthor || replyTo">
            Replying to

            <span
              *ngIf="replyTo && postAuthor.UserId !== replyTo.UserId"
              class="font-semibold text-gray-800 dark:text-gray-200"
            >
              &#64;{{ replyTo | userName }} and
            </span>
            <span class="font-semibold text-gray-800 dark:text-gray-200">
              &#64;{{ postAuthor | userName }}
            </span>
          </div>
        </div>
      </div>
      <div class=" pb-3">
        <div class="flex items-start space-x-3">
          <div class="relative">
            <img
              class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
              loading="lazy"
              [src]="(account$ | async).AvatarUrl | createSource"
              src-fallback="assets/images/user-image.png"
            />
          </div>
          <div class="min-w-0 flex-1">
            <comment-form #commentFormEl [text]="text"></comment-form>
          </div>
        </div>
      </div>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [``],
})
export class SocialCommentV2Modal implements OnInit, OnDestroy {
  static modalId = 'commentv2-modal';
  @Input({ required: true }) postId: number;
  @Input() commentId: number;
  @Input() commentParentId: number;
  @Input() postAuthor: IUserShort;
  @Input() replyTo?: IUserShort;
  @Input() text?: string = '';
  @ViewChild(IonContent, { static: true }) content: IonContent;
  // isLoading = false;
  tagSearch = '';
  isCommenting = false;
  topComments: IComment[] | null = null;
  topCommentsSkeleton: null[] = [];
  @ViewChild('commentFormEl')
  commentFormEl: CommentFormComponent;

  isCommentLoading$: Observable<boolean>;
  account$ = this.store.select(userSelectors.selectAccount);

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<IState>,
    private readonly actions$: Actions
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.isCommentLoading$ = this.store.pipe(
      select(socialSelectors.isCommentLoading(this.postId))
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        this.dismiss();
      });
  }

  ngOnDestroy() {}

  ionViewDidEnter(): void {
    // this.loadComments();
    this.commentFormEl.focusAndCursorToEnd();
  }

  // loadComments() {
  //   this.isLoading = true;
  //   this.topCommentsSkeleton = Array(this.post?.Comments || 0).fill(null);
  //   this.cdr.markForCheck();
  //   this.socialService
  //     .getComments(this.post.Id)
  //     .pipe(
  //       tap({
  //         next: (comments) => (this.topComments = comments),
  //       }),
  //       finalize(() => {
  //         this.isLoading = false;
  //         this.cdr.markForCheck();
  //       })
  //     )
  //     .subscribe();
  // }

  goToAt(y = 0) {
    this.content.scrollByPoint(0, y, 0);
  }

  submit() {
    if (this.commentFormEl.formGroup.invalid) {
      this.commentFormEl.formGroup.markAllAsTouched();
      return;
    }
    const { text } = this.commentFormEl.formGroup.value;
    this.isCommenting = true;
    this.cdr.markForCheck();

    if (this.commentId) {
      // editing
      this.actions$
        .pipe(
          ofType(
            socialActions.SocialPostEditCommentSuccess,
            socialActions.SocialPostEditCommentError
          ),
          first(),
          finalize(() => {
            this.isCommenting = false;
            this.cdr.markForCheck();
          })
        )
        .subscribe(({ type }) => {
          console.log({ type });
          if (type === socialActions.SocialPostEditCommentSuccess.type) {
            this.dismiss();
          }
        });

      this.store.dispatch(
        socialActions.SocialPostEditCommentRequest({
          postId: this.postId,
          commentId: this.commentId,
          text,
        })
      );
    } else {
      // new comment
      this.actions$
        .pipe(
          ofType(
            socialActions.SocialPostAddCommentSuccess,
            socialActions.SocialPostAddCommentError
          ),
          first(),
          finalize(() => {
            this.isCommenting = false;
            this.cdr.markForCheck();
          })
        )
        .subscribe(({ type }) => {
          if (type === socialActions.SocialPostAddCommentSuccess.type) {
            this.dismiss();
          }
        });
      this.store.dispatch(
        socialActions.SocialPostAddCommentRequest({
          postId: this.postId,
          commentParentId: this.commentParentId,
          text,
        })
      );
    }
  }

  dismiss() {
    this.modalService.dismiss(
      {
        dismissed: true,
      },
      undefined,
      SocialCommentV2Modal.modalId
    );
  }
}
