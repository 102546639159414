import { Component, Input, ViewChild } from '@angular/core';
import { QuaggaJSCodeReader, QuaggaJSResultObject } from '@ericblade/quagga2';
import { ModalService } from '@services/modal.service';
import debug from 'debug';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

import { BarcodeScannerLivestreamComponent } from './barcode-scanner-livestream/barcode-scanner-livestream.component';

const log = debug('cs:plantern:ScannerModal');

@Component({
  selector: 'web-scanner-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Scanner</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-item color="danger" *ngIf="error">
        <ion-label class="ion-text-wrap"> {{ error }} </ion-label>
      </ion-item>
      <!-- <zxing-scanner
        #scanner
        [enable]="true"
        [(device)]="desiredDevice"
        [formats]="formats"
        (camerasFound)="camerasFoundHandler($event)"
        (camerasNotFound)="camerasNotFoundHandler($event)"
        (scanSuccess)="scanSuccessHandler($event)"
        (scanError)="scanErrorHandler($event)"
        (permissionResponse)="onPermissionResponse($event)"
      ></zxing-scanner> -->
      <barcode-scanner-livestream
        [types]="formats"
        [errorFilter]="{ median: 0.08, threshold: 0.1 }"
        [torch]="false"
        (valueChanges)="onValueChanges($event)"
        (started)="onStarted($event)"
        (error)="error = $event"
      ></barcode-scanner-livestream>
    </ion-content>
  `,
})
export class ScannerWebModalComponent {
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;

  @Input() formats: QuaggaJSCodeReader[] = [
    'code_128_reader',
    'code_128_reader',
    'ean_reader',
    'ean_5_reader',
    'ean_2_reader',
    'ean_8_reader',
    'code_39_reader',
    'code_39_vin_reader',
    'codabar_reader',
    'upc_reader',
    'upc_e_reader',
    'i2of5_reader',
    '2of5_reader',
    'code_93_reader',
    'code_32_reader',
  ];
  desiredDevice: MediaDeviceInfo | null = null;
  error = '';

  constructor(private modalService: ModalService) {
    addIcons({ close });
  }

  ngAfterViewInit(): void {
    this.barcodeScanner.start();
  }

  onValueChanges(result: QuaggaJSResultObject): void {
    if (result.codeResult.code) {
      this.modalService.dismiss(
        {
          barcode: result.codeResult.code.trim(),
        },
        'success'
      );
    }
  }

  onStarted(event): void {
    // console.log('started', event);
  }

  onPermissionResponse(perm: boolean) {
    if (!perm) {
      this.error =
        'The app does not have the permission to use the camera. Please enable the camera access in the OS/Browser settings.';
    }
  }

  scanSuccessHandler(text: string) {
    log('scanSuccessHandler', text, typeof text);
    if ('vibrate' in navigator) {
      navigator.vibrate(200);
    }
    this.modalService.dismiss(
      {
        barcode: text.trim(),
      },
      'success'
    );
  }
  scanErrorHandler(e: Error) {
    log('scanErrorHandler', e);
    this.error = e.message;
  }

  dismiss() {
    this.modalService.dismiss(null, 'cancel');
  }
}
